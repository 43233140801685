import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
// import ReactDatatable from "react-data-table-component";
import styled from '../../assets/css/user.module.css';

import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { AdminAction, GetUserList } from '../../axioscalls/useaxios';
import { toast } from 'react-toastify';

function AdminList() {

    const Wallet_Details = useSelector((state) => state.wallet_detail)

    var location = useLocation();
    const { pathname, state } = location;
    const path = pathname.split("/")[1]

    const [adminlist, setAdminList] = useState([]);
    const [contents, setContents] = useState([]);

    const columns = [
        {
            key: "",
            name: "SNO",
            className: "NFT",
            align: "left",
            sortable: true,
            cell: (record, index) =>
                <div>{index + 1}
                </div>

        },
        {
            key: "EmailId",
            name: "Email-Id",
            className: "NFT NAME",
            align: "left",
            sortable: true,
            cell: rec => rec?.EmailId

        },
        {
            key: "Activated",
            name: "Status",
            className: "NFT NAME",
            align: "left",
            sortable: true,
            cell: rec => (rec?.Activated ? "Active" : "De-Activated"),

        },
        {
            key: "isAdmin",
            name: "Edit Admin",
            className: "NFT IDT",
            align: "left",

            cell: record =>
                <div><Link to={{ pathname: '/editadmin' }} state={record} ><button className=' allbtn allbtns' type='button'>Edit</button></Link></div>

        },
        {
            key: "isAdmin",
            name: "Activate/De-Activate",
            className: "NFT IDT",
            align: "left",

            cell: record =>
                <div><button className=' allbtn allbtns' type='button' onClick={() => ActivateAction(record, "delete")}>{record?.Activated ? "De-Activate" : "Activate"}</button></div>

        },
    ]



    useEffect(() => {
        GetAdminList()
    }, [])

    const GetAdminList = async () => {
        const resp = await AdminAction({
            action: 'get',
            id: Wallet_Details?.payload?._id,
        });
        console.log("GetAdminListresp", resp)
        if (resp?.status)
            setAdminList(resp.data);

    }


    const ActivateAction = async (data) => {

        const resp = await AdminAction({
            action: 'activate',
            id: data._id,
        })

        console.log("ActivateActionresp", resp)

        if (resp?.status) {
            toast.success(resp?.msg)
            GetAdminList()

        }
        else return toast.error(resp.msg)
    }


    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>

                            <div className={`${styled.tablesec}`}>
                                <h5 className={`${styled.cardtitle}`}>USER LIST</h5>
                                <Link to="/addadmin"><button type='button' className={`themebtn ${styled.addbtn} ${styled.mtb}`}>Add</button></Link>


                                {/* <div className={`row ${styled.formsec} ${styled.mtb}`}>
                  <div className={`col-xl-6 col-xxl-4 ms-auto`}>
                    <input
                      type="text"
                      className={'formcontrol'}
                      placeholder="Search"
                      value={filterText}
                      // onChange={handleSearch}
                      style={{ padding: '0.8vh', width: '100%' }}
                    />
                  </div>
                </div> */}

                                <DataTable
                                    className='react-datatables'
                                    columns={columns}
                                    data={adminlist}
                                    pagination // Enable pagination
                                    // paginationPerPage={2} // Rows per page
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]} // Page options
                                />
                            </div>
                        </div>


                    </div>
                </div>

            </Layout>

        </div>
    )
}

export default AdminList