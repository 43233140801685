import React, { useState, Fragment } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import dummyimg from "../../assets/images/dummypf.png";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FirstCaps, isEmpty } from '../../lib/common';
import { DepositSupportAction, ReActivateAction, SupportListAction, WithDrawSupportAction } from '../../axioscalls/useaxios';
import config from '../../lib/config'

function Reactivatedetail() {

  const navigate = useNavigate();
  const location = useLocation()

  // const { detail }=props;
  const [userdata, Setuserdata] = useState(location.state)
  const [detail, Setdetails] = useState(location.state)
  const [Errors, SetErrors] = useState({})
  const [DisableStatus, SetDisableStatus] = useState(false);
  // const detail = location.state

  const OnChange = (e) => {
    SetErrors({})
    let id = e?.target?.id;
    let value = e?.target?.value;
    Setdetails({
      ...detail,
      ...{ [id]: value },
    })
  }

  const Validate = (type) => {
    let error = {};
    if (type == 'rejected') {
      if (isEmpty(detail?.Message)) {
        error.Message = "Enter Reject Reason to Reject"
      }
    }
    return error;
  }

  const Submit = async (type) => {
    SetDisableStatus(true);
    // let toastid = toast.loading("Validating...");
    let error = Validate(type)
    if (isEmpty(error)) {
      let resp = await ReActivateAction({ action: 'update', Id: detail?._id, Status: type, Message: detail?.Message })
      console.log('ReActivateAction_resp', resp)
      if (resp?.status) {

        toast.success(resp?.msg, 1000)
        // toast.update(toastid,{render:resp?.msg,autoClose:1000,type:'success',isLoading:false})
        navigate('/reactivatelist')
      }
      else {
        // toast.update(toastid,{render:resp?.msg,autoClose:1000,type:'error',isLoading:false})
        toast.error(resp?.msg, 1000)
      }
    }
    else {
      SetErrors(error)
      // toast.update(toastid,{render:"Fix all Validations",autoClose:1000,type:'error',isLoading:false})
      toast.error("Fix all Validations")
    }
    SetDisableStatus(false);
  }


  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>
              <div className={`${styled.tablesec}`}>
                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                <h5 className={`${styled.cardtitle}`}>User Details</h5>

                <form>
                  <div className={`row ${styled.formsec}`}>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>User Name</label>
                      <input type="text" disabled={true} className={`formcontrol`} placeholder='name' value={detail?.UserId?.UserName} />
                    </div>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>Profile</label>
                      {/* <img src={dummyimg} className={`${styled.dummyimg} d-block`}/> */}
                      {isEmpty(detail?.UserId?.Profile) ? <img className={`${styled.dummyimg} d-block`} src={dummyimg} alt="Pnorofile" />
                        : <img className={`${styled.dummyimg} d-block`} src={`${config.IMG_URL}/profile/${detail?.UserId?._id}/${detail?.UserId?.Profile}`} alt="Profile" />}
                    </div>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>Email</label>
                      <input type="email" disabled={true} className={`formcontrol`} placeholder='para@gmail.com' value={detail?.UserId?.EmailId} />
                    </div>


                  </div>
                </form>

                {
                  detail?.Status != 'accepted'
                    ?
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>Reject Message:(Reject Message is Required for Rejection)</label>
                      <input type="text" className={`formcontrol`} id="Message" placeholder="Enter Reject Reason" value={detail?.Message} onChange={(e) => OnChange(e)} disabled={detail?.Status != 'pending'} />
                      {
                        Errors?.Message ?
                          <span className='error_msg'>{Errors?.Message}</span>
                          :
                          <></>
                      }
                    </div>

                    :
                    <></>
                }
                {
                  detail?.Status == 'pending' ?
                    <>
                      <button className={`themebtn ${styled.addbtn} ${styled.mts}`} type='button' onClick={() => Submit("accepted")} disabled={detail?.Status != 'pending' || DisableStatus} >Activate</button>

                      <br />

                      <button className={`themebtn ${styled.addbtn} ${styled.mts}`} type='button' onClick={() => Submit("rejected")} disabled={detail?.Status != 'pending' || DisableStatus} >Reject</button>
                    </>
                    :
                    <p>The Request has been {FirstCaps(detail?.Status)}</p>
                }


              </div>
            </div>
          </div>
        </div>
      </Layout>

    </div>
  )
}

export default Reactivatedetail