import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
// import ReactDatatable from "react-data-table-component";
import DataTable from 'react-data-table-component';
import styled from '../../assets/css/user.module.css';
import { SupportListAction } from '../../axioscalls/useaxios';


function Supportticketlist() {

  var location = useLocation();
  const { pathname, state } = location;
  const path = pathname.split("/")[1]


  const [userList, setUserList] = useState([])

  const columns = [
    {
      key: "",
      name: "SNO",
      className: "NFT",
      align: "left",
      sortable: true,
      cell: (record, index) =>
        <div>{index + 1}
        </div>

    },
    {
      key: "Email",
      name: "Email Address",
      className: "NFT",
      align: "left",
      cell: rec =>
        <div title={rec.EmailId}>{rec?.UserId?.EmailId}</div>
    },
    {
      key: "UserName",
      name: "Name",
      className: "NFT",
      align: "left",
      cell: rec =>
        <div title={rec.FirstName}>{(rec?.UserId?.UserName?.length > 13 ? rec?.UserId?.UserName.toString().slice(0, 5) + '...' + rec?.UserId?.UserName.toString().slice(-5) : rec?.UserId?.UserName)}</div>
    },
    {
      key: "Subject",
      name: "Subject",
      className: "NFT",
      align: "left",
      cell: rec =>
        <div title={rec.Subject}>{(rec?.Subject?.length > 13 ? rec?.Subject.toString().slice(0, 5) + '...' + rec?.Subject.toString().slice(-5) : rec?.Subject)}</div>
    },


    {
      name: "Detail",
      cell: record =>
        <div><Link to={{ pathname: `/supportticketdetail` }} state={record} ><button className=' allbtn'>view</button></Link></div>

    },

  ]

  useEffect(() => {
    getUsers();
  }, [])

  const getUsers = async () => {
    const resp = await SupportListAction({ action: 'get' });
    console.log("SupportListAction_resp", resp);
    if (resp?.status) {
      setUserList(resp?.data);
    }
  }


  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>


              <div className={`${styled.tablesec}`}>
                <h5 className={`${styled.cardtitle}`}>SUPPORT TICKET LIST</h5>

                {/* <div className={`row ${styled.formsec} ${styled.mtb}`}>
                  <div className={`col-xl-6 col-xxl-4 ms-auto`}>
                    <input
                      type="text"
                      className={'formcontrol'}
                      placeholder="Search"
                      value={filterText}
                      onChange={handleSearch}
                      style={{ padding: '0.8vh', width: '100%' }}
                    />
                  </div>
                </div> */}

                <DataTable
                  className='react-datatables'
                  columns={columns}
                  data={userList}
                  pagination // Enable pagination
                  // paginationPerPage={2} // Rows per page
                  paginationRowsPerPageOptions={[5, 10, 15, 20]} // Page options
                />

                {/* <ReactDatatable
                  className='react-datatables'

                  config={config}
                  data={records}
                  columns={columns}
                // customStyles={customStyles}
                // extraButtons={extraButtons}
                /> */}
              </div>
            </div>


          </div>
        </div>

      </Layout>

    </div>
  )
}

export default Supportticketlist