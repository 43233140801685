import io from 'socket.io-client'
import Config from './lib/config'
export const socket = io(Config.IMG_URL, {
    upgrade: false, reconnection: true,
    reconnectionAttempts: Infinity,
})

export var SocketId;
socket.on('connected', () => {
    SocketId = socket.id
})

