import React from 'react'
import styled from '../assets/css/header.module.css';
import { GiHamburgerMenu } from "react-icons/gi";
import Images from '../assets/images/images';
import Dropdown from 'react-bootstrap/Dropdown';
import { Log_Out } from '../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgEmpty } from '../lib/common';
import config from '../lib/config'

function Header({ Onmenuopen }) {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onclickmenu = () => {
    Onmenuopen(true);
  };

  const Wallet_Details = useSelector((state) => state.wallet_detail);

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("walletConnectType");
    document.cookie =
      "admintoken" + "= ; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    dispatch({
      type: Log_Out,
    });
    localStorage.removeItem("adminlogin");
    navigate("/");
  };

  return (
    <div className={`${styled.header}`}>
      <div className={`container customcontainer px-0`}>
        <div className={`row mx-0`}>
          <div className={`col-12 px-0`}>
            <div className={`d-flex align-items-center justify-content-between`}>
              <div>

                <img src={Images.logo} alt="logo" className={`${styled.logoimg}`} />
              </div>
              <div className={`d-flex align-items-center gap-3`}>
                <Dropdown className={`${styled.dropstyled}`}>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={ImgEmpty(Wallet_Details?.payload?.Profile) ? Images.pfimg : `${config.IMG_URL}/admin/${Wallet_Details?.payload?._id}/${Wallet_Details?.payload?.Profile}` }  alt="logo" className={`${styled.pfimg}`} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={()=>navigate('/profile')} >Profile</Dropdown.Item>

                    <Dropdown.Item href="#" onClick={() => logout()} >Log out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <div className={`${styled.hamburger} d-block d-xl-none`}>
                  <GiHamburgerMenu fill='#fff' size={24} onClick={logout} />
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>


    </div>
  )
}

export default Header