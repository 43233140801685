import config from "../lib/config.js";
// import 
import { AppenData, Encryptdata } from '../lib/encode_decode.js';
import { axiosFunc } from '../lib/common.js';

const jwttoken = localStorage.getItem("token")

export const LoginAdmin = async(payload)=>{
    // let data = {"data":Encryptdata(payload)}
    try{
        let resp = await axiosFunc({
            'method':'POST',
            'url':`${config.AdminAPI}/adminlogin`,
            'data' : payload 
            
        })
        if(resp.data.token)
            localStorage.setItem("token",resp.data.token);

        return resp.data;
    }
    catch(err){
        console.log("LoginAdmin_err",err)
    }


}

export const WebhookAction = async (data) => {
    try {
        let resp = await axiosFunc({
            'method': 'post',
            'url': `${config.AdminAPI}/webhookaction`,
            'data': data,
        })
        return resp.data;
    }
    catch(err){console.log("err in WebhookAction owner",err)}
}

export const SiteURLAction = async (data) => {
    try {
        let resp = await axiosFunc({
            'method': 'post',
            'url': `${config.AdminAPI}/urlaction`,
            'data': data,
        })
        return resp.data;
    }
    catch(err){console.log("err in SiteURLAction owner",err)}
}

export const GetUserList  = async()=>{
    try{
        let resp = await axiosFunc({
                'method':'GET',
                'url':`${config.AdminAPI}/userlist`,
                "headers":{
                    "authorization": jwttoken
                },
        })
        
        return resp.data;
    }
    catch(err){
        console.log("GetUserList_error",err)
    }
  }

  export const UserdetailGet  = async(data)=>{
    try{
        let resp = await axiosFunc({
                'method':'GET',
                'url':`${config.AdminAPI}/userdetail`,
                params: data,
                "headers":{
                    "authorization": jwttoken
                },
        })
        
        return resp.data;
    }
    catch(err){
        console.log("UserdetailGet_error",err)
    }
  }

  export const KYCAction = async (data) => {
    try {
        let resp = await axiosFunc({
            'method': 'post',
            'url': `${config.AdminAPI}/kycaction`,
            'data': data,
            "headers":{
                "authorization": jwttoken
            },
        })
        return resp.data;
    }
    catch(err){
        console.log("err in KYCAction",err)
    }
}

export const KYCSettingAction  = async(data)=>{
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/kycsettingaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("KYCSettingAction_error",err)
    }
}

export const KYCFieldSettingAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/kycfieldsettingaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("KYCFieldSettingAction_error",err)
    }
}

export const SupportListAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/supportlistaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("SupportListAction_error",err)
    }
}

export const ReActivateAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/reactivateaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("ReActivateAction_error",err)
    }
}

export const UserAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/useraction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("UserAction_error",err)
    }
}

export const UserNameAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/username`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("UserNameAction_error",err)
    }
}

export const DepositSupportAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/depositsupportaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("DepositSupportAction_error",err)
    }
}

export const WithDrawSupportAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/withdrawsupportaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("WithDrawSupportAction_error",err)
    }
}

export const AdminAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'POST',
                'url':`${config.AdminAPI}/adminaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("AdminAction_error",err)
    }
}

export const AuthenticateUser = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'GET',
                'url':`${config.AdminAPI}/authenticateuser`,
                params: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("AuthenticateUser_error",err)
    }
}

export const ForgotPasswordAction = async (data) => {
    try{
        let resp = await axiosFunc({
                'method':'post',
                'url':`${config.AdminAPI}/forgotpasswordaction`,
                data: data,
        })
        
        return resp.data;
    }
    catch(err){
        console.log("ForgotPasswordAction_error",err)
    }
}

export const GetChatSetting = async () => {
    try {
        let resp = await axiosFunc({
            'method':'get',
            'url':`${config.AdminAPI}/getchatsetting`,
        })
        return resp.data;
    }
    catch (err) {
        console.log("GetChatSetting_error", err)
    }
}

export const UpdateChatSetting = async (data) => {
    try {
        let resp = await axiosFunc({
            'method':'post',
            'url':`${config.AdminAPI}/updatechatsetting`,
            data: data,
        })
        return resp.data;
    }
    catch (err) {
        console.log("UpdateChatSetting_error", err)
    }
}

export const EditProfile = async(data) => {
    try{
        let formData = AppenData(data)
        let resp = await axiosFunc({
            'method':'post',
            'url':`${config.AdminAPI}/editprofile`,
            data: formData?.[0],
        })
        return resp.data;
    }
    catch(err){
        console.log('EditProfile_error', err)
    }
}
