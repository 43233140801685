// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_profileImg__tjYa5 {
  width: 5vw;
  height: 10vh;
  border-radius: 50%;
  object-fit: cover;
  min-width: 70px;
  min-height: 70px;
}

.profile_profile_details__0oaJ0 .profile_uploadImg__q0aH6 {
  display: flex;
  align-items: center;
  /* grid-gap: 20px; */
  gap: 1.6vw;
}

.profile_profile_details__0oaJ0 .profile_upload_btn_wrapper__TXULE {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.profile_profile_details__0oaJ0 .profile_upload_btn_wrapper__TXULE .profile_btn__HHLsP {
  border: 1px solid gray;
  color: #000000de;
  background-color: white;
  padding: 0.8vh 1.2vw;
  border-radius: 0.7vh;
  font-size: 1.6vh;
}

.profile_profile_details__0oaJ0 .profile_upload_btn_wrapper__TXULE input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/profile.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;EACV,WAAW;EACX,YAAY;AACd","sourcesContent":[".profileImg {\n  width: 5vw;\n  height: 10vh;\n  border-radius: 50%;\n  object-fit: cover;\n  min-width: 70px;\n  min-height: 70px;\n}\n\n.profile_details .uploadImg {\n  display: flex;\n  align-items: center;\n  /* grid-gap: 20px; */\n  gap: 1.6vw;\n}\n\n.profile_details .upload_btn_wrapper {\n  position: relative;\n  overflow: hidden;\n  display: inline-block;\n}\n\n.profile_details .upload_btn_wrapper .btn {\n  border: 1px solid gray;\n  color: #000000de;\n  background-color: white;\n  padding: 0.8vh 1.2vw;\n  border-radius: 0.7vh;\n  font-size: 1.6vh;\n}\n\n.profile_details .upload_btn_wrapper input[type=\"file\"] {\n  position: absolute;\n  left: 0;\n  top: 0;\n  opacity: 0;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileImg": `profile_profileImg__tjYa5`,
	"profile_details": `profile_profile_details__0oaJ0`,
	"uploadImg": `profile_uploadImg__q0aH6`,
	"upload_btn_wrapper": `profile_upload_btn_wrapper__TXULE`,
	"btn": `profile_btn__HHLsP`
};
export default ___CSS_LOADER_EXPORT___;
