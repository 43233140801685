
import config from '../lib/config.js'
import {AppenData, Decryptdata  , Encryptdata } from "../lib/encode_decode.js"
import { axiosFunc } from "../lib/common.js"

const jwttoken = localStorage.getItem("token")

export const getInstances = async() =>{
    try{
        let resp = await axiosFunc({
          
                'method':'get',
                'url':`${config.AdminAPI}/getinstances`,
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
        })
        return resp.data;
    }
    catch(err){console.log("err in getInstances",err)}
}

export const getMessages = async (data)=>{
    try{
        let resp = await axiosFunc({
          
                'method':'post',
                'url':`${config.AdminAPI}/getmessages`,
                data : data,
                "headers":{
                    'Content-Type': 'application/json',
                    "Authorization": jwttoken
                },
        })
        return resp.data;
    }
    catch(err){console.log("err in getMessages",err)}
}

export const clostInstance = async (data)=>{
    try{
        var senddata    =   {
            method  :   'post',
            url     :   `${config.CHATAPI}/close`,
            data    :   data, 
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp.data
    }
    catch(err){
        console.log('clostInstance_err-->',err)
    }
}

export const Seenchagne = async (data)=>{
    try{
        let resp = await axiosFunc({
            'method':'post',
            'url':`${config.CHATAPI}/seen`,
            data : data,
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": jwttoken
            },
    })
    resp.data = resp?.data
    return resp.data;
    }catch(err){
        console.log('Seenchagne_err-->',err)
    }
}

export const sendMsg = async (data)=>{
    try{
        let formData = AppenData(data)
        var resp    = await axiosFunc({
                method  :   'post',
                url     :   `${config.CHATAPI}/sendmsg`,
                data    :   formData[0], 
            "headers":{
                'Content-Type': 'application/json',
                "Authorization": jwttoken
            },
    })
    resp.data = resp?.data
    return resp.data;
    }
    catch(err){
        console.log('sendMsg_err-->',err)
    }
    
    }