import React, { useState, Fragment, useEffect } from "react";
import Layout from "../../components/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import ReactDatatable from "react-data-table-component";
import styled from "../../assets/css/user.module.css";

import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { AdminAction, GetUserList } from "../../axioscalls/useaxios";
import { toast } from "react-toastify";
import { isEmpty } from "../../lib/common";
import config from "../../lib/config";
import Select from "react-select";
import { Form } from "react-bootstrap";

const initpermission = {
  user: false,
  supportticket: false,
  chat: false,
  depositsupport: false,
  withdrawsupport: false,
  reactivate: false,
  namechange: false,
  // webhook: false,
  // kycsetting: false,
  // kycfieldsetting: false,
  // admin: false,
};

const permissiontext = {
  user: "User Module",
  supportticket: "Support Ticket",
  chat: "Chat Support",
  depositsupport: "Deposit Suppport",
  withdrawsupport: "WithDraw Support",
  reactivate: "ReActivate Support",
  namechange: "Name Change Request",
  // webhook: "WebHook Module",
  // kycsetting: "KYC Settings",
  // kycfieldsetting: "KYC Field Settings",
  // admin: "Admin",
};

const ActivateOptions = [
  {
    label: "Activate",
    value: true,
  },
  {
    label: "De-Activate",
    value: false,
  },
];

function AddAdmin() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let data = location?.state;
    if (!isEmpty(data?._id)) {
      GetAdminDetail(data);
    }
    // data = history.location.state
    // setFormData(data)
    // setAnser(data?.answer)
    // setQuestion(data?.question)
    // setId(data?._id)
  }, []);

  const GetAdminDetail = async (data) => {
    let resp = await AdminAction({
      action: "getadmin",
      id: data?._id,
    });
    console.log("GetAdminDetailresp", resp?.data);
    if (resp?.data) {
      setFormData({
        ...formData,
        ...resp?.data,
      });
    } else {
      toast.error("Invalid Admin");
      navigate("/adminlist");
    }
  };

  const initData = {
    EmailId: "",
    Password: "",
    Permission: initpermission,
    Activated: true,
    ConfirmPassword: "",
  };

  const [formData, setFormData] = useState(initData);
  const [Errors, SetErrors] = useState({});
  const [DisableStatus, SetDisableStatus] = useState(false);

  const handlechange = async (e) => {
    try {
      const { id, value, name } = e.target;
      if (name == "Permission") {
        setFormData({
          ...formData,
          ...{
            Permission: {
              ...formData?.Permission,
              ...{ [id]: !formData?.Permission?.[id] },
            },
          },
        });
      } else {
        setFormData({
          ...formData,
          ...{ [id]: value },
        });
      }
    } catch (err) {
      console.log(err, "handlechange__er");
    }
  };

  const Validate = () => {
    let error = {};
    if (isEmpty(formData?.EmailId)) {
      error.EmailId = "Enter Email-Id";
    } else if (!config.EMAIL.test(formData?.EmailId)) {
      error.EmailId = "Enter Valid Email-Id";
    }
    if (isEmpty(location?.state?._id)) {
      if (isEmpty(formData?.Password)) {
        error.Password = "Enter Password";
      } else if (!config.PASSWORD.test(formData?.Password)) {
        error.Password =
          "Password must contain alphanumeric, must contain 8 to 15 letters.";
      }
      if (isEmpty(formData?.ConfirmPassword)) {
        error.ConfirmPassword = "Enter Confirm Password";
      } else if (formData?.ConfirmPassword != formData?.Password) {
        error.ConfirmPassword = "Password and Confirm Password Must be Same.";
      }
    }
    return error;
  };

  const handleSubmit = async () => {
    SetDisableStatus(true);
    let validateerror = Validate();
    console.log("validateerrorvalidateerror", validateerror);
    let toastid = toast.loading("Validating..");
    if (isEmpty(validateerror)) {
      let payload = {};
      if (location?.state?._id) {
        payload = {
          EmailId: formData?.EmailId,
          Permission: formData?.Permission,
          Activated: formData?.Activated,
          action: "edit",
          id: formData?._id,
        };
        // payload.action = 'add'
      } else {
        payload = {
          EmailId: formData?.EmailId,
          Permission: formData?.Permission,
          Activated: formData?.Activated,
          Password: formData?.Password,
          ConfirmPassword: formData?.ConfirmPassword,
          action: "add",
        };
      }

      const resp = await AdminAction(payload);
      console.log("AdminAction_resp", resp);

      if (resp?.status) {
        // toast.success(resp.message)
        toast.update(toastid, {
          render: resp.msg,
          type: "success",
          autoClose: 1000,
          isLoading: false,
        });
        navigate("/adminlist");
      } else {
        // return toast.error(resp.message)
        SetDisableStatus(false);
        return toast.update(toastid, {
          render: resp.msg,
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      }
    } else {
      toast.update(toastid, {
        render: "Fix all validions",
        type: "error",
        autoClose: 1000,
        isLoading: false,
      });
      SetErrors(validateerror);
    }
    SetDisableStatus(false);
  };

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>
              <div className={`${styled.tablesec}`}>
                <h5 className={`${styled.cardtitle}`}>
                  {location?.state ? "Edit" : "Add"} Admin
                </h5>
                <button
                  type="button"
                  className={`themebtn ${styled.addbtn}`}
                  onClick={() => navigate(-1)}
                >
                  back
                </button>

                <form>
                  <div className={`row ${styled.formsec}`}>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <div className="mb-2">
                        <label for="exampleInputEmail1" className={`formlabel`}>
                          Email-Id
                        </label>
                        <input
                          type="text"
                          className={`formcontrol`}
                          id="EmailId"
                          value={formData?.EmailId}
                          onChange={(e) => handlechange(e)}
                        />
                      </div>
                      {isEmpty(location?.state?._id) ? (
                        <>
                          <div className="mb-2">
                            <label
                              htmlFor="exampleInputName1"
                              className={`formlabel`}
                            >
                              Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="Password"
                              value={formData?.Password}
                              onChange={(e) => handlechange(e)}
                            />
                          </div>
                          <div className="mb-2">
                            <label
                              htmlFor="exampleInputName1"
                              className={`formlabel`}
                            >
                              Confirm Password
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="ConfirmPassword"
                              value={formData?.ConfirmPassword}
                              onChange={(e) => handlechange(e)}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>
                        Activate/De-Activate
                      </label>
                      <Select
                        options={ActivateOptions}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ...{ Activated: e.value },
                          })
                        }
                        value={ActivateOptions.find(
                          (val) => val.value == formData?.Activated
                        )}
                      />
                    </div>

                    <Form.Group className="edit_admin">
                      <h5 className="moduleTitle-txt mt-3">Modules </h5>
                      {Object.keys(permissiontext).map((val) => {
                        return (
                          <>
                            <Form.Check
                              type="checkbox"
                              className="form-control"
                              id={val}
                              name="Permission"
                              checked={formData?.Permission?.[val]}
                              onChange={(e) => handlechange(e)}
                              label={permissiontext?.[val]}
                            />
                          </>
                        );
                      })}
                    </Form.Group>
                  </div>
                </form>
                <button
                  type="button"
                  className={`themebtn ${styled.addbtn} ${styled.mts}`}
                  onClick={() => handleSubmit()}
                  disabled={DisableStatus}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default AddAdmin;
