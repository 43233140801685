import React, { useState, useRef, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom';
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import { Decryptdata, Encryptdata } from '../../lib/encode_decode';
import { SocketId, socket } from '../../socket';
import { Seenchagne, clostInstance, getMessages, sendMsg } from '../../axioscalls/chat';
import { ShortString, isEmpty } from '../../lib/common';
import { toast } from 'react-toastify';
import person from "../../assets/images/person.png";
import { IoMdAttach } from "react-icons/io";
import { IoMdDocument } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdAccessTime } from "react-icons/md";
import { MdOutlinePlayCircle } from "react-icons/md";
import { IoSend } from "react-icons/io5";
import config from '../../lib/config';

let instchat = [];

function Chatdetail() {

    const navigate = useNavigate();
    const location = useLocation()

    const [detail, Setdetails] = useState(location.state)
    const [chattextboxes, setChattextboxes] = useState([]);
    const [DisableStatus, SetDisableStatus] = useState(false);
    const [msg, setMsg] = useState("")
    const [files, setFiles] = useState([])
    const fileref = useRef()
    const chatContainerRef = useRef(null);

    useEffect(() => {
        socket.on("adminget", async (data) => {
            let Msg = await Decryptdata(data)
            seenchange()
            setChattextboxes([...instchat, Msg])
            instchat = [...instchat, Msg]
        })
    }, [socket])

    useEffect(() => {
        fetch()
        seenchange()
    }, [])

    const handleClick = () => {
        fileref.current.click()
    }

    const fetch = async () => {
        let resp = await getMessages({ id: detail?._id, SocketId: SocketId })
        console.log('getMessagesresp', resp)
        if (resp?.success === "success") {
            instchat = resp?.data?.chat
            setChattextboxes(resp?.data?.chat)
        }
    }

    const getfiles = async (e) => {
        let file = e.target.files
        let err = ""
        const ext = ['image', 'video', 'pdf']
        await Promise.all(Object.values(file).map((data) => {
            if (!ext.some((test) => data?.type.includes(test))) {
                err = "please upload image or video or pdf files only"
            }
        }))
        if (!isEmpty(err)) return toast.error(err)
        setFiles([...files, ...Object.values(file)])
    }

    const removeImg = (index) => {
        let allfile = files;
        allfile.splice(index, 1)
        setFiles([...allfile])
    }

    const msgSend = async () => {
        if (isEmpty(msg)) return toast.error("Please enter Some Message")
        let sendData = {
            id: detail?._id,
            msg,
            messager: "admin",
            seen: true
        }
        socket.emit('adminchat', Encryptdata(sendData))
        setChattextboxes([...chattextboxes, sendData])
        setMsg("")
    }

    const messageSend = async () => {
        SetDisableStatus(true);
        if (isEmpty(msg) && files.length == 0) {
            SetDisableStatus(false);
            return toast.error("Please enter Some Message or File")
        }
        let sendData = {
            id: detail?._id,
            msg,
            messager: "admin",
            files: files,
            SocketId: SocketId,
        }
        let resp = await sendMsg(sendData)
        console.log('sendMsgresp', resp)
        if (resp?.success === "success") {
            instchat = [...instchat, resp?.data]
            setChattextboxes([...chattextboxes, resp?.data])
            setMsg("")
            setFiles([])
        }
        SetDisableStatus(false);
    }

    const seenchange = async () => {
        let resp = await Seenchagne({ id: detail?._id })
    }

    const closeSession = async () => {
        let resp = await clostInstance({ id: detail?._id })
        if (resp?.success == "success") {
            toast.success(resp?.msg)
        }
    }


    useEffect(() => {
        // Scroll to the bottom of the chat container when messages change
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chattextboxes]);



    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>

                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>

                                <h5 className={`${styled.cardtitle}`}>CHAT DETAIL</h5>

                                <div className=''>
                                    <div className=''>
                                        <div className='d-flex align-items-center mb-3'>
                                            <img src={person} className='img-fluid personimg me-2' />
                                            <h5 className='mb-0 chatname'>{detail?.EmailId}</h5>
                                        </div>

                                        <div>
                                            <div className="chattext_box" ref={chatContainerRef}>
                                                {chattextboxes.length > 0 && chattextboxes.map((msg) => (

                                                    <>
                                                        {console.log('msgmsg', msg)}
                                                        {msg?.files?.length > 0 && msg.files?.map((file, i) => {
                                                            if (file?.includes('.webp')) {
                                                                return (
                                                                    <div className={msg.messager !== "admin" ? "box_left mb-3" : "box_right mb-3"} style={{ width: "fit-content", minWidth: "fit-content" }}>
                                                                        <h6 className={msg.messager !== "admin" ? "textleft textorg" : "textend textgray"} data-toggle="tooltip" data-placement="top" title={msg.messager !== "admin" ? detail?.EmailId : (msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId)}>{msg.messager !== "admin" ? 'user' : ShortString((msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId), 12)}</h6>
                                                                        <a href={`${config.IMG_URL}/${file}`} target='_blank' ><img src={`${config.IMG_URL}/${file}`} className="img-fluid chatimage" /></a>
                                                                        <p className="mb-0 timepos "><MdAccessTime /> {`${new Date(msg?.createdAt).getHours() > 12 ? `${new Date(msg?.createdAt).getHours() - 12}` : new Date(msg?.createdAt).getHours()}.${new Date(msg?.createdAt).getMinutes()}`} {new Date(msg?.createdAt).getHours() >= 12 ? "PM" : "AM"}</p>
                                                                    </div>
                                                                )
                                                            }
                                                            else if (file?.includes('.webm')) {
                                                                return (
                                                                    <div className={msg.messager !== "admin" ? "box_left mb-3" : "box_right mb-3"} style={{ width: "fit-content", minWidth: "fit-content" }}>
                                                                        <h6 className={msg.messager !== "admin" ? "textleft textorg" : "textend textgray"} data-toggle="tooltip" data-placement="top" title={msg.messager !== "admin" ? detail?.EmailId : (msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId)}>{msg.messager !== "admin" ? 'user' : ShortString((msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId), 12)}</h6>
                                                                        <a className='text-white' href={`${config.IMG_URL}/${file}`} target='_blank' ><video src={`${config.IMG_URL}/${file}`} className="img-fluid chatimage" />
                                                                            <p className="mb-0 timepos "><MdAccessTime /> {`${new Date(msg?.createdAt).getHours() > 12 ? `${new Date(msg?.createdAt).getHours() - 12}` : new Date(msg?.createdAt).getHours()}.${new Date(msg?.createdAt).getMinutes()}`} {new Date(msg?.createdAt).getHours() >= 12 ? "PM" : "AM"}</p>
                                                                            <MdOutlinePlayCircle className="playpos" /></a>
                                                                    </div>)
                                                            }
                                                            else {
                                                                return (
                                                                    <>

                                                                        <div className={msg.messager !== "admin" ? "box_left mb-3 text-wrap" : "box_right mb-3 text-wrap"} style={{ width: "120px", minWidth: "120px" }}>
                                                                            <h6 className={msg.messager !== "admin" ? "textleft textorg" : "textend textgray"} data-toggle="tooltip" data-placement="top" title={msg.messager !== "admin" ? detail?.EmailId : (msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId)} >{msg.messager !== "admin" ? 'user' : ShortString((msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId), 12)}</h6>
                                                                            <a href={`${config.IMG_URL}/${file}`} className='text-white' target='_blank' ><span className="uploadlogos"><IoMdDocument className="h-25" style={{ width: "50px", fill: "#000" }} /><p className='mt-1 mb-2 text-dark' >{msg?.filenames?.[i]}</p></span></a>
                                                                            <p className="mb-0 timepos"><MdAccessTime /> {`${new Date(msg?.createdAt).getHours() > 12 ? `${new Date(msg?.createdAt).getHours() - 12}` : new Date(msg?.createdAt).getHours()}.${new Date(msg?.createdAt).getMinutes()}`} {new Date(msg?.createdAt).getHours() >= 12 ? "PM" : "AM"}</p>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        }
                                                        )}
                                                        {!isEmpty(msg.msg) &&
                                                            <>
                                                                <div className={msg.messager !== "admin" ? "box_left mb-3" : "box_right mb-3"} >
                                                                    <h6 className={msg.messager !== "admin" ? "textleft textorg" : "textend textgray"} data-toggle="tooltip" data-placement="top" title={msg.messager !== "admin" ? detail?.EmailId : (msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId)}>{msg.messager !== "admin" ? 'user' : ShortString((msg?.AdminId?.UserName ? msg?.AdminId?.UserName : msg?.AdminId?.EmailId), 12)}</h6>

                                                                    <p className="mb-2 textbreak">
                                                                        {msg.msg}
                                                                    </p>
                                                                    <p className="mb-0 timepos"><MdAccessTime /> {`${new Date(msg?.createdAt).getHours() > 12 ? `${new Date(msg?.createdAt).getHours() - 12}` : new Date(msg?.createdAt).getHours()}.${new Date(msg?.createdAt).getMinutes()}`} {new Date(msg?.createdAt).getHours() >= 12 ? "PM" : "AM"}</p>

                                                                </div>
                                                            </>
                                                        }

                                                    </>
                                                )
                                                )}
                                            </div>
                                            {files?.length > 0 && <div className="px-2 wrapss">
                                                {
                                                    files?.map((img, i) => {
                                                        return (
                                                            <div className="pos widthset">
                                                                {
                                                                    img.type.includes('image') ?
                                                                        <img src={URL.createObjectURL(img)} className="img-fluid uploadlogos" />
                                                                        :
                                                                        img.type.includes('video') ?
                                                                            <video src={URL.createObjectURL(img)} className="img-fluid uploadlogos" />
                                                                            :
                                                                            <span className="uploadlogos d-flex align-items-center justify-content-center flex-column"><IoMdDocument className="w-25 h-25 text-dark" /><p className='mb-0 text-dark'>{img.name}</p></span>
                                                                }
                                                                <IoCloseCircleOutline className="closeicon" onClick={() => removeImg(i)} /> </div>
                                                        )

                                                    })
                                                }
                                            </div>}
                                            <div className="px-2 mt-3">
                                                <div class="input-group gap-1 mb-2">
                                                    <input type="text" class="form-control" placeholder="Message" onChange={(e) => setMsg(e.target.value)} value={msg} aria-label="Recipient's username" aria-describedby="basic-addon2" onKeyDown={(e) => (e.key == 'Enter' ? messageSend() : null)} disabled={DisableStatus} />
                                                    <div class="input-group-append ml-2">
                                                        <input type="file" className="d-none" multiple ref={fileref} onChange={getfiles} />
                                                        <button class=" allbtn" type="button" onClick={() => handleClick()}><IoMdAttach /></button>
                                                    </div>
                                                    <div class="input-group-append ml-2">
                                                        <button class=" allbtn" type="button" onClick={() => messageSend()} disabled={DisableStatus}><IoSend /></button>
                                                    </div>
                                                </div></div>
                                        </div>


                                    </div>
                                </div>
                                <div className='textend px-2 mt-4'><button class=" allbtn" onClick={() => closeSession()} type="button">End Task</button></div>

                            </div>
                        </div>
                    </div>


                </div>

            </Layout>

        </div>
    )
}

export default Chatdetail