import React, { useEffect, useState } from 'react'
import styled from '../assets/css/sidebar.module.css'
import { useLocation, NavLink, Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { MdMenu } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import Images from '../assets/images/images';
import { FaUser } from "react-icons/fa";
import { useSelector } from 'react-redux';

function Sidebar(props) {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mbmenu, setMbmenu] = useState(false);

  const [sidemenu, setSidemenu] = useState([])

  const Wallet_Details = useSelector((state) => state.wallet_detail)
  console.log('Wallet_Detailasas---->', Wallet_Details);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  useEffect(() => {
    if (props.menustate) {
      setMbmenu(props.menustate)
    }
  }, [props.menustate])

  useEffect(() => {
    settingSideRoute()
  }, [Wallet_Details])

  const getRoutes = (route) => {
    switch (route) {
      case "Support Ticket":
        return {
          label: "Support Ticket",
          icon: <FaUser />,
          path: "/supportticketlist",
        }

      case "Chat":
        return {
          label: "Chat",
          icon: <FaUser />,
          path: "/chatlist",
        }

      case "Deposit support ticket":
        return {
          label: "Deposit support ticket",
          icon: <FaUser />,
          path: "/depositsupportlist",
        }
      case "Withdraw support ticket":
        return {
          label: "Withdraw support ticket",
          icon: <FaUser />,
          path: "/withdrawsupportlist",
        }
      case "ReActive Support":
        return {
          label: "ReActive Support",
          icon: <FaUser />,
          path: "/reactivatelist",
        }
      case "Name change request":
        return {
          label: "Name change request",
          icon: <FaUser />,
          path: "/namechangerequestlist",
        }
      case "Webhooks":
        return {
          label: "Webhooks",
          icon: <FaUser />,
          path: "/webhook",
        }
      case "Kyc Settings":
        return {
          label: "Kyc Settings",
          icon: <FaUser />,
          path: "/kycsetting",
        }
      case "Kyc Field Settings":
        return {
          label: "Kyc Field Settings",
          icon: <FaUser />,
          path: "/kycfieldsetting",
        }
      case "Admin":
        return {
          label: "Admin",
          icon: <FaUser />,
          path: "/adminlist",
        }
      default :
      return {
        label: "Not a route",
        icon: <FaUser />,
        path: "#",
      }
    }
  }

  const settingSideRoute = () => {
    let route = [
      {
        label: "Users",
        icon: <FaUser />,
        path: "/users",
      }
    ]

    if (Wallet_Details?.payload?.Role == "superadmin") {
      route = [
        ...route,
        {
          label: "Support Ticket",
          icon: <FaUser />,
          path: "/supportticketlist",
        },
        {
          label: "Chat",
          icon: <FaUser />,
          path: "/chatlist",
        },
        {
          label: "Deposit support ticket",
          icon: <FaUser />,
          path: "/depositsupportlist",
        },
        {
          label: "Withdraw support ticket",
          icon: <FaUser />,
          path: "/withdrawsupportlist",
        },
        {
          label: "ReActive Support",
          icon: <FaUser />,
          path: "/reactivatelist",
        },
        {
          label: "Name change request",
          icon: <FaUser />,
          path: "/namechangerequestlist",
        },
        {
          label: "Webhooks",
          icon: <FaUser />,
          path: "/webhook",
        },
        {
          label: "Kyc Settings",
          icon: <FaUser />,
          path: "/kycsetting",
        },
        {
          label: "Kyc Field Settings",
          icon: <FaUser />,
          path: "/kycfieldsetting",
        },
        {
          label: "Admin",
          icon: <FaUser />,
          path: "/adminlist",
        },
      ]
    } else {
      if (Wallet_Details?.payload?.Permission?.supportticket) route.push(getRoutes("Support Ticket"));
      if (Wallet_Details?.payload?.Permission?.chat) route.push(getRoutes("Chat"));
      if (Wallet_Details?.payload?.Permission?.depositsupport) route.push(getRoutes("Deposit support ticket"));
      if (Wallet_Details?.payload?.Permission?.withdrawsupport) route.push(getRoutes("Withdraw support ticket"));
      if (Wallet_Details?.payload?.Permission?.reactivate) route.push(getRoutes("ReActive Support"));
      if (Wallet_Details?.payload?.Permission?.namechange) route.push(getRoutes("Name change request"));
      if (Wallet_Details?.payload?.Permission?.webhook) route.push(getRoutes("Webhooks"));
      if (Wallet_Details?.payload?.Permission?.kycsetting) route.push(getRoutes("Kyc Settings"));
      if (Wallet_Details?.payload?.Permission?.kycfieldsetting) route.push(getRoutes("Kyc Field Settings"));
      if (Wallet_Details?.payload?.Permission?.admin) route.push(getRoutes("Admin"));
    }
    console.log('route---->', route);
    setSidemenu(route)
  }
  const closefun = () => {
    setMbmenu(false);
    props.handlemenuopen(false)
  }
  return (
    <>
      {/* <div className={`d-block d-xl-none ${styled.menubar}`}>
        <MdMenu onClick={() => setMbmenu(true)} />
      </div> */}
      <div className={windowWidth > 1199 ? `${styled.sidebarsec}` : mbmenu ? `${styled.sidebarsec} ${styled.mobilemenu} ${styled.mobilemenuopen}` : `${styled.sidebarsec} ${styled.mobilemenu}`}>
        <div className={`d-block d-xl-none ${styled.closeicons}`}>
          <IoClose onClick={closefun} />
        </div>
        <div className={`${styled.siderow}`}>

          <div className={`${styled.menulist}`}>
            <ul className={`${styled.ulstyle}`}>

              {sidemenu.map((e, i) => (
                <li><NavLink title={e.label} to={e.path}><span className={`${styled.iconimg}`}>{e.icon}</span>{e.label}</NavLink></li>

              ))}
            </ul>


          </div>

        </div>

      </div>
    </>
  )
}

export default Sidebar