
// ----->  Redux Explanation  <-------\\

// step one action -->  usedispatch  -- trigger change inital state data  / pass {reducer type , payload state}
// step two reducer --> to update   -- change initial state data using action data passed from dispatch
//                                     in wallet_details fn
// step three store --> use useSelector() hook to access store state variable






export const Account_Connect = 'Account_Connect';
export const Account_disConnect = 'Account_disConnect';
export const Initial_Connect = 'Initial_Connect';
export const Admin_Login = 'Admin_Login';
export const Log_Out = "Log_Out";

const initial_wallet = {
  payload     :   null,
  loggedin    :   'unknown',    // 'unknown', 'false', 'true'
  token       :   '',         //  '', 'jwttoken', 'skip'
}


function wallet_details(state = initial_wallet,action){
   
        switch (action.type) {
            case Initial_Connect:
              return {
              ...state,
              ...action.Initial_Connecting
              };
            case Account_Connect:
              return {
              ...state,
              ...action.Account_Detail
              };
            case Account_disConnect:
              return {
              ...state,
              ...action.Account_Detail_Disconnect
              };
            case Admin_Login:
            return {
            ...state,
            ...action.Admin_connection
            };
            case Log_Out:
              return {
                payload: null,
                loggedin: 'false',
                token: '',
              };
            default:
              return state;
    }

}


export default wallet_details;