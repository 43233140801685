import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import dummyimg from "../../assets/images/dummypf.png";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../lib/common';
import { DepositSupportAction, SupportListAction, WebhookAction, WithDrawSupportAction } from '../../axioscalls/useaxios';
import config from '../../lib/config'
import { Decryptdata } from '../../lib/encode_decode';

function Editwebhook() {

    const navigate = useNavigate();
    const location = useLocation()

    let [formdata, SetFormData] = useState({})

    const handlechange = (e) => {
        e.preventDefault();
        const { id, value } = e.target;

        SetFormData({ ...formdata, [id]: value });
    }

    useEffect(() => {
        if (location?.state) {
            GetWebhook();
        }
    }, [location?.state])

    const GetWebhook = async () => {
        if (location?.state?.id) {
            let paramdata = location?.state?.id;
            let decstring = Decryptdata(paramdata);
            if (decstring) {
                let Resp = await WebhookAction({ type: "get", webhookid: decstring });
                console.log('WebhookAction_resp', Resp)
                if (Resp?.status) {
                    SetFormData({ ...formdata, webhookid: Resp?.data?._id, Url: Resp?.data?.Url })
                }
                else {
                    toast.error("Try-Again")
                    navigate('/webhook')
                }
            }
            else {
                toast.error("Try-Again")
                navigate('/webhook')
            }
        }
        else {
            toast.error("Try-Again")
            navigate('/webhook')
        }
    }

    const handleSubmit = async () => {
        let senddata = {
            type: 'update',
            ...formdata,
        }
        let Resp = await WebhookAction(senddata);
        if (Resp?.status) {
            toast.success("Updated Successfully")
            navigate('/webhook')
        }
        else {
            toast.error("Try Again")
        }
        console.log('WebhookAction_resp', Resp)
    }
    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                                <h5 className={`${styled.cardtitle}`}>Edit webhook</h5>

                                <form>
                                    <div className={`row ${styled.formsec}`}>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>User Name</label>
                                            <input type="text" id="Url" className={`formcontrol`} placeholder="Enter Url" value={formdata?.Url || ''} onChange={(e) => handlechange(e)} />
                                        </div>

                                    </div>
                                </form>

                                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`} onClick={() => handleSubmit()} >Submit</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </div>
    )
}

export default Editwebhook