import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter, useLocation } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { useSelector } from 'react-redux';
import AppRoutes from './AppRoutes';
import { UseHook } from './lib/common';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { socket } from './socket';
function App() {

  const location = useLocation();
  const Wallet_Details = useSelector((state) => state.wallet_detail)


  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      console.log = () => { };
      console.warning = () => { };
      console.info = () => { };
      console.error = () => { };
    }
  }, []);

  useEffect(() => {
    socket.on('connected', () => {
      console.log('socketiddd', socket?.id)
    })
  }, [socket])

  return (
    <>
      <UseHook />
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
    // <Routes>
    //   <Route exact path="/" element={<Login />} />

    //   {/* users */}
    //   <Route exact path="/users" element={<Users />} />
    //   <Route exact path="/userdetail" element={<Userdetail />} />
    //   <Route exact path="/kycdetail" element={<Kycdetail />} />

    //   {/* support ticket */}
    //   <Route exact path="/supportticketlist" element={<Suppportticket />} />
    //   <Route exact path="/supportticketdetail" element={<Supportticketdetail />} />

    //   {/* chat */}
    //   <Route exact path="/chatlist" element={<Chatlist />} />
    //   <Route exact path="/chatdetail" element={<Chatdetail />} />
    //   <Route exact path="/chatsettings" element={<Chatsettings />} />

    //   {/* <Route path="/settings" element={<Settings />} /> */}
    // </Routes>
  );
}

export default App;
