// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__fXehU .header_logoimg__YOBrM{
    width: 15vh;
    /* height: 10vh; */
    height: 6vh;
}
.header_header__fXehU .header_pfimg__xloap{
    width: 5vh;
    height: 5vh;
    object-fit: cover;
    border-radius: 50%;
}
.header_header__fXehU{
    /* background: var(--bgclr); */
    background: var(--blackclr);
    padding: 0.5vh 0;
}
.header_header__fXehU .header_dropstyled__BG-p4 button{
    background: none;
    border: none;

}
.header_header__fXehU .header_dropstyled__BG-p4 button:after{
    background: none !important;
    display: none;
}
.header_header__fXehU .header_dropstyled__BG-p4 button:active{
    background: none;
    border: none;  
}
.header_header__fXehU .header_dropstyled__BG-p4 [class="dropdown-menu show"]{
    width: 10vw;
    background: var(--blackclr);
}
.header_header__fXehU .header_dropstyled__BG-p4 [class="dropdown-menu show"] a{
    color: var(--whtclr);
    font-size: 2vh;
    padding: 1vh 2vh;
}
.header_header__fXehU .header_dropstyled__BG-p4 [class="dropdown-menu show"] a:hover{
background: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,YAAY;;AAEhB;AACA;IACI,2BAA2B;IAC3B,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,WAAW;IACX,2BAA2B;AAC/B;AACA;IACI,oBAAoB;IACpB,cAAc;IACd,gBAAgB;AACpB;AACA;AACA,gBAAgB;AAChB","sourcesContent":[".header .logoimg{\n    width: 15vh;\n    /* height: 10vh; */\n    height: 6vh;\n}\n.header .pfimg{\n    width: 5vh;\n    height: 5vh;\n    object-fit: cover;\n    border-radius: 50%;\n}\n.header{\n    /* background: var(--bgclr); */\n    background: var(--blackclr);\n    padding: 0.5vh 0;\n}\n.header .dropstyled button{\n    background: none;\n    border: none;\n\n}\n.header .dropstyled button:after{\n    background: none !important;\n    display: none;\n}\n.header .dropstyled button:active{\n    background: none;\n    border: none;  \n}\n.header .dropstyled [class=\"dropdown-menu show\"]{\n    width: 10vw;\n    background: var(--blackclr);\n}\n.header .dropstyled [class=\"dropdown-menu show\"] a{\n    color: var(--whtclr);\n    font-size: 2vh;\n    padding: 1vh 2vh;\n}\n.header .dropstyled [class=\"dropdown-menu show\"] a:hover{\nbackground: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__fXehU`,
	"logoimg": `header_logoimg__YOBrM`,
	"pfimg": `header_pfimg__xloap`,
	"dropstyled": `header_dropstyled__BG-p4`
};
export default ___CSS_LOADER_EXPORT___;
