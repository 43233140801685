import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import dummyimg from "../../assets/images/dummypf.png";
import { toast } from 'react-toastify';
import { KYCSettingAction, UserAction, UserdetailGet } from '../../axioscalls/useaxios';
import config from '../../lib/config';
import { NumANdDotOnly, isEmpty } from '../../lib/common';

function KycSettings() {

    const navigate = useNavigate()
    const location = useLocation()
    // const { detail }=props;
    const [userdata, Setuserdata] = useState(location.state)
    const [detail, Setdetails] = useState(location.state)
    const [Error, SetError] = useState({});
    // const detail = location.state

    useEffect(() => {
        fetch()
    }, [])
    const fetch = async () => {
        const res = await KYCSettingAction({ action: 'get' });
        console.log('KYCSettingActionfetchresp', res)
        if (res?.status) {
            Setdetails(res.data)
        }
        else {
            Setdetails({});
            toast.error(res?.msg)
        }
    }

    const Validate = () => {
        let error = {}

        //      Becauseadmin can set empty for this

        // if(isEmpty(detail?.defaultDeposit)){
        //     error.defaultDeposit = "Enter Amount"
        // }
        // if(isEmpty(detail?.defaultWithDraw)){
        //     error.defaultWithDraw = "Enter Amount"
        // }

        //      Becauseadmin can set empty for this

        if (isEmpty(detail?.PersonalDetailsDeposit)) {
            error.PersonalDetailsDeposit = "Enter Amount"
        }
        if (isEmpty(detail?.PersonalDetailsWithDraw)) {
            error.PersonalDetailsWithDraw = "Enter Amount"
        }
        if (isEmpty(detail?.PhysicalAddressWithDraw)) {
            error.PhysicalAddressWithDraw = "Enter Amount"
        }
        if (isEmpty(detail?.PhysicalAddressDeposit)) {
            error.PhysicalAddressDeposit = "Enter Amount"
        }
        if (isEmpty(detail?.BioMetricWithDraw)) {
            error.BioMetricWithDraw = "Enter Amount"
        }
        if (isEmpty(detail?.BioMetricDeposit)) {
            error.BioMetricDeposit = "Enter Amount"
        }
        return error;
    }


    const Update = async () => {
        try {
            // let toastid = toast.loading("Updating...")
            let errors = Validate();
            if (isEmpty(errors)) {
                let res = await KYCSettingAction({ action: 'update', data: detail });
                console.log('KYCSettingActionUpdateresp', res)
                if (res?.status) {
                    // Setdetails(res.data)
                    fetch();
                    toast.success("Updated Successfully", 1000)
                }
                else {
                    Setdetails({});
                    toast.error(res?.msg, 1000);
                }
            }
            else {
                toast.error("Fix all Validations", 1000);
                SetError(errors);
            }
        }
        catch (err) {
            console.log('Update_error', err)
        }
    }

    const OnChange = (e) => {
        let id = e?.target?.id ? e?.target?.id : e?.id;
        let value = e?.target?.value
        Setdetails({
            ...detail,
            ...{
                [id]: isEmpty(value) ? '' : NumANdDotOnly(value),
            }
        })
    }
    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                                <h5 className={`${styled.cardtitle}`}>Spend Limitation</h5>

                                <form>
                                    <div className={`row ${styled.formsec}`}>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>No Detail: (Withdraw Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="defaultWithDraw" placeholder="e.x.500" value={detail?.defaultWithDraw} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.defaultWithDraw}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>No Detail: (Deposit Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="defaultDeposit" placeholder="e.x.500" value={detail?.defaultDeposit} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.defaultDeposit}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Personal Detail: (WithDraw Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="PersonalDetailsWithDraw" placeholder="e.x.500" value={detail?.PersonalDetailsWithDraw} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.PersonalDetailsWithDraw}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Personal Detail: (Deposit Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="PersonalDetailsDeposit" placeholder="e.x.500" value={detail?.PersonalDetailsDeposit} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.PersonalDetailsDeposit}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Physical Address: (WithDraw Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="PhysicalAddressWithDraw" placeholder="e.x.500" value={detail?.PhysicalAddressWithDraw} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.PhysicalAddressWithDraw}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Physical Address: (Deposit Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="PhysicalAddressDeposit" placeholder="e.x.500" value={detail?.PhysicalAddressDeposit} onChange={(e) => OnChange(e)} />
                                            <span className='error_msg'>{Error?.PhysicalAddressWithDraw}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Bio-Metric: (WithDraw Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="BioMetricWithDraw" placeholder="e.x.500" value={(detail?.BioMetricWithDraw == 'nolimit' ? "unlimited" : detail?.BioMetricWithDraw)} disabled={detail?.BioMetricWithDraw == 'nolimit'} onChange={(e) => OnChange(e)} />

                                            <div className='kyc_form_card_footer'>
                                                <p className='kyc_form_card_footer_p'>If you want set it unlimited:</p>
                                                <div className='kyc_form_card_footer_content'>
                                                    <input
                                                        type='checkbox'
                                                        checked={detail?.BioMetricWithDraw == 'nolimit'}
                                                        id="BioMetricWithDraw"
                                                        onChange={(e) => Setdetails({ ...detail, ...{ BioMetricWithDraw: (detail?.BioMetricWithDraw == 'nolimit' ? '' : 'nolimit') } })}
                                                    />
                                                    <p>Select this if you want to set it unlimited</p>
                                                </div>
                                            </div>

                                            <span className='error_msg'>{Error?.BioMetricWithDraw}</span>

                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Bio-Metric: (Deposit Limitation)</label>
                                            <input type="text" className={`formcontrol`} id="BioMetricDeposit" placeholder="e.x.500" value={(detail?.BioMetricDeposit == 'nolimit' ? "unlimited" : detail?.BioMetricDeposit)} disabled={detail?.BioMetricDeposit == 'nolimit'} onChange={(e) => OnChange(e)} />

                                            <div className='kyc_form_card_footer'>
                                                <p className='kyc_form_card_footer_p'>If you want set it unlimited:</p>
                                                <div className='kyc_form_card_footer_content'>
                                                    <input
                                                        type='checkbox'
                                                        checked={detail?.BioMetricDeposit == 'nolimit'}
                                                        id="BioMetricDeposit"
                                                        onChange={(e) => Setdetails({ ...detail, ...{ BioMetricDeposit: (detail?.BioMetricDeposit == 'nolimit' ? '' : 'nolimit') } })} />
                                                    <p>Select this if you want to set it unlimited</p>
                                                </div>
                                            </div>
                                            <span className='error_msg'>{Error?.BioMetricDeposit}</span>

                                        </div>


                                    </div>
                                </form>

                                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`}  onClick={() => Update()}>Update</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </div>
    )
}

export default KycSettings;