import React, { useState } from 'react';
import cascading from '../assets/css/layout.module.css'
import Header from './Header';
import Sidebar from './sidebar';

function Layout({ children, props }) {
  const [menu, setMenu] = useState();
  const handlemenuopen = (newData) => {
    setMenu(newData);
  };
  return (
    <div className={`${cascading.layoutpage}`}>
        <Header Onmenuopen={handlemenuopen}/>
        <div className={`${cascading.layoutsec} d-flex`}>
            <div className={`${cascading.sidebarsec}`}>
              <Sidebar menustate={menu} handlemenuopen={handlemenuopen}/>
            </div>
            <div className={`${cascading.contentsec}`}>
                {children}
              </div>

        </div>


        
    </div>
  )
}

export default Layout;