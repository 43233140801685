import React, { Component, useState, useEffect } from "react";

import { Button, Form, FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import noimg from "../../assets/images/edafacelogo.png";
import noimg1 from "../../assets/images/photo_camera.webp";
import styled from "../../assets/css/user.module.css";

import {
  KYCAction,
  KYCFieldSettingAction,
  KYCSettingAction,
  UserdetailGet,
} from "../../axioscalls/useaxios.js";
import { FirstCaps, NumANdDotOnly, isEmpty } from "../../lib/common.js";
import { FaPlus, FaTrash } from "react-icons/fa6";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/layout.jsx";

function KYCFieldSetting(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const TypeOptions = [
    {
      label: "Personal Details",
      value: "PersonalDetailsFields",
    },
    {
      label: "Physical Address",
      value: "PhysicalAddressFields",
    },
    {
      label: "Bio Metric",
      value: "BioMetricFields",
    },
  ];

  const InputTypeOptions = [
    {
      label: "Text-Box",
      type: "text",
      value: "text-input",
      tagname: "input",
      FirstKey: "Enter",
      InputType: "input",
    },
    {
      label: "Select Dropdown",
      type: undefined,
      value: "select",
      tagname: "select",
      InputType: "select",
      FirstKey: "Select",
      Options: [{ name: "" }],
    },
    {
      label: "Date-Of-Birth",
      type: "date",
      value: "date-input",
      tagname: "input",
      FirstKey: "Enter",
      InputType: "input",
    },
    {
      label: "Mobile-Input",
      type: undefined,
      value: "PhoneInput",
      tagname: "PhoneInput",
      FirstKey: "Enter",
      InputType: "PhoneInput",
    },
    {
      label: "File Upload",
      type: "file",
      value: "file-input",
      tagname: "input",
      FirstKey: "Upload",
      InputType: "input",
    },
    {
      label: "Email-Id",
      type: "email",
      value: "email-input",
      tagname: "input",
      InputType: "input",
      FirstKey: "Enter",
      disabled: true,
    },
    {
      label: "Live Image",
      type: "Webcam",
      value: "Webcam-Webcam",
      tagname: "Webcam",
      FirstKey: "Capture",
      InputType: "Webcam",
    },
  ];

  // const { detail }=props;
  const [userdata, Setuserdata] = useState(location.state);
  const [detail, Setdetails] = useState({});
  const [Error, SetError] = useState({});
  // const detail = location.state

  const [choosenKycTabs, setChoosenKycTabs] = useState("personalDetails");
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const res = await KYCFieldSettingAction({ action: "get" });
    console.log("KYCFieldSettingAction_resp", res);
    if (res?.status) {
      Setdetails({
        ...detail,
        ...(res?.data ? res.data : {}),
        Type: "PersonalDetailsFields",
        label: "Personal Details",
      });
    } else {
      Setdetails({});
      toast.error(res?.msg);
    }
  };

  const Validate = () => {
    let error = {};
    if (isEmpty(detail?.Type)) {
      error.Type = "Select Type";
    }
    if (!detail?.[detail?.Type]?.length) {
      error.Field = "Fields Are Required";
    }
    if (detail?.[detail?.Type]?.length > 0) {
      detail[detail?.Type].map((val, ind) => {
        if (isEmpty(val?.InputType)) {
          error[`InputType${ind}`] = "Select Input Type";
        } else if (val?.InputType == "select") {
          if (Array.isArray(val?.Options) && val?.Options?.length > 0) {
            val.Options.map((option, i) => {
              if (isEmpty(option)) {
                error[`name${ind}${i}`] = "Enter Option 1";
              }
            });
          } else {
            error[`Option${ind}`] = "Add Option";
          }
        }
        if (isEmpty(val?.FieldName)) {
          error[`FieldName${ind}`] = "Enter Field Name";
        }
      });
    }
    return error;
  };

  const Update = async () => {
    try {
      // let toastid = toast.loading("Updating...")
      let errors = Validate();
      if (isEmpty(errors)) {
        let res = await KYCFieldSettingAction({
          action: "update",
          data: detail,
        });
        console.log("KYCFieldSettingActionUpdateresp", res);
        if (res?.status) {
          // Setdetails(res.data)
          fetch();
          toast.success("Updated Successfully", 1000);
        } else {
          Setdetails({});
          toast.error(res?.msg, 1000);
        }
      } else {
        toast.error("Fix all Validations", 1000);
        SetError(errors);
      }
    } catch (err) {
      console.log("Update_error", err);
    }
  };

  const OnChange = (e, ind, i) => {
    let id = e?.target?.id ? e?.target?.id : e?.id;
    let value = e?.target?.value;
    let name = e?.target?.name;
    SetError({});
    if (typeof i == "number") {
      Setdetails({
        ...detail,
        ...{
          [detail?.Type]: [
            ...detail?.[detail?.Type]?.slice(0, ind),
            ...[
              {
                ...detail?.[detail?.Type]?.[ind],
                ...{
                  [name]: [
                    ...(detail?.[detail?.Type]?.[ind]?.[name]).slice(0, i),
                    ...[
                      {
                        ...detail?.[detail?.Type]?.[ind]?.[name]?.[i],
                        ...{
                          [id]: FirstCaps(value),
                          label: FirstCaps(value),
                          value: FirstCaps(value),
                        },
                      },
                    ],
                    ...(detail?.[detail?.Type]?.[ind]?.[name]).slice(
                      i + 1,
                      detail?.[detail?.Type]?.[ind]?.[name]?.length
                    ),
                  ],
                },
              },
            ],
            ...detail?.[detail?.Type]?.slice(
              ind + 1,
              detail?.[detail?.Type]?.length
            ),
          ],
        },
      });
    } else {
      Setdetails({
        ...detail,
        ...{
          [detail?.Type]: [
            ...detail?.[detail?.Type]?.slice(0, ind),
            ...[
              {
                ...detail?.[detail?.Type]?.[ind],
                ...{
                  [id]:
                    id == "FieldName"
                      ? ValueAlone(value, detail?.[detail?.Type]?.[ind])
                      : id == "Required"
                      ? !detail?.[detail?.Type]?.[ind]?.Required
                      : value,
                  ...(id == "FieldName"
                    ? {
                        id: String(
                          ValueAlone(value, detail?.[detail?.Type]?.[ind])
                        ).replaceAll(" ", ""),
                      }
                    : {}),
                },
              },
            ],
            ...detail?.[detail?.Type]?.slice(
              ind + 1,
              detail?.[detail?.Type]?.length
            ),
          ],
        },
      });
    }
  };

  const SelectOnChange = (e, name, ind) => {
    SetError({});
    if (typeof ind == "number") {
      Setdetails({
        ...detail,
        ...{
          [detail?.Type]: [
            ...detail?.[detail?.Type]?.slice(0, ind),
            ...[
              {
                ...detail?.[detail?.Type]?.[ind],
                ...e,
              },
            ],
            ...detail?.[detail?.Type]?.slice(
              ind + 1,
              detail?.[detail?.Type]?.length
            ),
          ],
        },
      });
    } else {
      Setdetails({
        ...detail,
        ...{
          [name]: e?.value,
          label: e?.label,
          [e?.value]: !isEmpty(detail?.[e?.value])
            ? detail?.[e?.value]
            : [
                {
                  InputType: "",
                  FieldName: "",
                  Required: false,
                },
              ],
        },
      });
    }
  };

  const AddField = () => {
    SetError({});
    if (detail?.Type) {
      Setdetails({
        ...detail,
        ...{
          [detail?.Type]: detail?.[detail?.Type]
            ? [
                ...detail?.[detail?.Type],
                {
                  InputType: "",
                  FieldName: "",
                  Required: false,
                },
              ]
            : [
                {
                  InputType: "",
                  FieldName: "",
                  Required: false,
                },
              ],
        },
      });
    } else {
      toast.error("Select Type");
    }
  };

  const AddOption = (ind) => {
    SetError({});
    Setdetails({
      ...detail,
      ...{
        [detail?.Type]: [
          ...detail?.[detail?.Type]?.slice(0, ind),
          ...[
            {
              ...detail?.[detail?.Type]?.[ind],
              Options: [
                ...(detail?.[detail?.Type]?.[ind]?.Options
                  ? detail?.[detail?.Type]?.[ind]?.Options
                  : []),
                { name: "" },
              ],
            },
          ],
          ...detail?.[detail?.Type]?.slice(
            ind + 1,
            detail?.[detail?.Type]?.length
          ),
        ],
      },
    });
  };

  const DeleteField = (ind) => {
    SetError({});
    Setdetails({
      ...detail,
      ...{
        [detail?.Type]: detail?.[detail?.Type]
          ? [
              ...detail?.[detail?.Type]?.slice(0, ind),
              ...detail?.[detail?.Type]?.slice(
                ind + 1,
                detail?.[detail?.Type]?.length
              ),
            ]
          : [],
      },
    });
  };

  const DeleteOption = (ind, i) => {
    SetError({});
    Setdetails({
      ...detail,
      ...{
        [detail?.Type]: [
          ...detail?.[detail?.Type]?.slice(0, ind),
          ...[
            {
              ...detail?.[detail?.Type]?.[ind],
              Options: [
                ...(detail?.[detail?.Type]?.[ind]?.Options).slice(0, i),
                ...(detail?.[detail?.Type]?.[ind]?.Options).slice(
                  i + 1,
                  detail?.[detail?.Type]?.[ind]?.Options?.length
                ),
              ],
            },
          ],
          ...detail?.[detail?.Type]?.slice(
            ind + 1,
            detail?.[detail?.Type]?.length
          ),
        ],
      },
    });
  };

  const ValueAlone = (value, alldata) => {
    if (alldata?.FirstKey) {
      value = value.split(`${alldata?.FirstKey} `)[1];
    }
    return value;
  };

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12 grid-margin stretch-card`}>
              <div className="card">
                <div className="card-body">
                  <button
                    className=" mt-2 allbtn"
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>

                  <h4 className="card-title mt-3 mb-3">
                    Fields in {detail?.label} KYC:
                  </h4>
                  <div className="kyc_tab_wrapper mb-3">
                    {TypeOptions.map((val) => {
                      return (
                        <button
                          className={`kyc_tab_wrapper_buttons ${
                            val?.value == detail.Type ? "active" : ""
                          }`}
                          onClick={() => SelectOnChange(val, "Type")}
                          type="button"
                        >
                          {val?.label}
                        </button>
                      );
                    })}
                    {/* <button className={`kyc_tab_wrapper_buttons ${choosenKycTabs === 'personalDetails' ? "active" : ""}`} onClick={() => setChoosenKycTabs("personalDetails")}>Personal Details</button> */}
                    {/* <button className={`kyc_tab_wrapper_buttons ${choosenKycTabs === 'physicalAddress' ? "active" : ""}`} onClick={() => setChoosenKycTabs("physicalAddress")}>Physical Address</button>
                            <button className={`kyc_tab_wrapper_buttons ${choosenKycTabs === 'bioMetric' ? "active" : ""}`} onClick={() => setChoosenKycTabs("bioMetric")}>Bio Metric</button> */}
                  </div>
                  <form className="forms-sample">
                    {/* <Form.Group>
                                <label>Select Type:</label>
                                <Select
                                    options={TypeOptions}
                                    onChange={(e) => SelectOnChange(e, "Type")}
                                    value={
                                        isEmpty(detail?.Type)
                                            ?
                                            {
                                                label: "Select Type",
                                                value: "Select Type"
                                            }
                                            :
                                            {
                                                label: detail?.label,
                                                value: detail?.Type
                                            }
                                    }
                                />
                                {
                                    Error?.Type
                                        ?
                                        <span className='error_msg'>{Error?.Type}</span>
                                        :
                                        <></>
                                }

                            </Form.Group> */}

                    {/* <button className='btn mt-2 allbtn' type='button' onClick={() => AddField()} >Add Field</button> */}
                    <br />
                    {Error?.Field ? (
                      <span className="error_msg">{Error?.Field}</span>
                    ) : (
                      <></>
                    )}

                    {detail?.[detail?.Type]?.length > 0 ? (
                      <>
                        {detail?.[detail?.Type]?.map((val, ind) => {
                          return (
                            <>
                              <div className="kyc_form_card">
                                <div className="kyc_form_card_header">
                                  <h5 className="kyc_form_card_title">
                                    Field {ind + 1}
                                  </h5>
                                  <button
                                    className="kyc_form_card_blue_button"
                                    type="button"
                                    onClick={() => AddField()}
                                  >
                                    <FaPlus /> Add New Field{" "}
                                  </button>
                                </div>

                                <div className="kyc_form_wrapper">
                                  <div className="kyc_form_select_wrapper">
                                    <label className="kyc_form_body_label">
                                      Select Input Type:
                                    </label>
                                    <Select
                                      options={InputTypeOptions}
                                      onChange={(e) =>
                                        SelectOnChange(e, "InputType", ind)
                                      }
                                      value={val}
                                    />
                                    {Error?.[`InputType${ind}`] ? (
                                      <span className="error_msg">
                                        {Error?.[`InputType${ind}`]}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <div>
                                    <label className="kyc_form_body_label">
                                      Enter Field Name:
                                    </label>
                                    <Form.Control
                                      type="text"
                                      className="kyc_form_body_input"
                                      id="FieldName"
                                      placeholder="e.x. Field Name"
                                      value={
                                        (val?.FirstKey
                                          ? val?.FirstKey + " "
                                          : "") + val?.FieldName
                                      }
                                      onChange={(e) => OnChange(e, ind)}
                                    />
                                    {Error?.[`FieldName${ind}`] ? (
                                      <span className="error_msg">
                                        {Error?.[`FieldName${ind}`]}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  <button
                                    className="kyc_form_card_delete_button"
                                    type="button"
                                    onClick={() => DeleteField(ind)}
                                  >
                                    <FaTrash />{" "}
                                  </button>
                                </div>

                                {val?.InputType == "select" ? (
                                  <div className="kyc_form_card_options">
                                    <div className="kyc_form_card_options_header">
                                      <p className="kyc_form_card_options_p">
                                        Options For the Select
                                      </p>
                                      <button
                                        className="kyc_form_card_options_add"
                                        type="button"
                                        onClick={() => AddOption(ind)}
                                      >
                                        <FaPlus /> Add Options
                                      </button>
                                    </div>

                                    {Error?.[`Option${ind}`] ? (
                                      <span className="error_msg">
                                        {Error?.[`Option${ind}`]}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {val?.Options?.length > 0 ? (
                                      <>
                                        {val?.Options?.map((option, i) => {
                                          return (
                                            <>
                                              <div className="mb-3 ">
                                                <label className="kyc_form_body_label">
                                                  Enter Option ({i + 1}):
                                                </label>

                                                <div
                                                  className="d-flex flex-wrap align-items-end"
                                                  style={{ gap: "15px" }}
                                                >
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control kyc_form_body_input"
                                                    id="name"
                                                    placeholder="e.x. Option"
                                                    name="Options"
                                                    value={option?.name}
                                                    onChange={(e) =>
                                                      OnChange(e, ind, i)
                                                    }
                                                  />
                                                  {Error?.[`name${ind}${i}`] ? (
                                                    <span className="error_msg">
                                                      {
                                                        Error?.[
                                                          `name${ind}${i}`
                                                        ]
                                                      }
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i > 0 ? (
                                                    <button
                                                      className="kyc_form_card_delete_button"
                                                      type="button"
                                                      onClick={() =>
                                                        DeleteOption(ind, i)
                                                      }
                                                    >
                                                      <FaTrash />
                                                    </button>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div className="kyc_form_card_footer">
                                  <p className="kyc_form_card_footer_p">
                                    If Field is Required Select it:
                                  </p>
                                  <div className="kyc_form_card_footer_content">
                                    <input
                                      type="checkbox"
                                      checked={val?.Required}
                                      id="Required"
                                      onChange={(e) => OnChange(e, ind)}
                                    />
                                    <p>Select if it is Required</p>
                                  </div>
                                </div>
                                {/* {
                                                                ind > 0
                                                                    ?
                                                                    <button className='kyc_form_card_delete_button' type='button' onClick={() => DeleteField(ind)} >Delete Field {ind + 1}</button>
                                                                    :
                                                                    <></>
                                                            } */}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}

                    <Form.Group className="mt-3">
                      <button
                        className={`themebtn ${styled.addbtn} ${styled.mts}`}
                        type="button"
                        onClick={() => Update()}
                      >
                        Update
                      </button>
                      {/* <button className='btn  ml-3 allbtn' type='button' onClick={() => setShowPreview(!showPreview)} >Preview</button> */}
                    </Form.Group>

                    <div className="kyc_preview_card mt-4">
                      <h5 className="kyc_form_card_title">
                        This is the Preview of KYC Form
                      </h5>
                      <div className="mt-2">
                        {detail?.[detail?.Type]?.length > 0 ? (
                          <>
                            {detail?.[detail?.Type]?.map((val) => {
                              return (
                                <>
                                  {isEmpty(val?.InputType) ? (
                                    <label>No Input Selected</label>
                                  ) : (
                                    <div className="mb-2 d-flex flex-column ">
                                      <label className="kyc_form_body_label ">
                                        {(val?.FirstKey
                                          ? val?.FirstKey + " "
                                          : "") + val.FieldName}{" "}
                                        :{val?.Required ? "(Required)" : ""}
                                      </label>
                                      {val?.InputType == "input" ? (
                                        val?.type == "file" ? (
                                          <div>
                                            <img
                                              src={noimg}
                                              className="img-fluid imgset mb-2"
                                            />
                                            <br></br>
                                            <input
                                              type="file"
                                              className="kyc_form_body_input mt-1 mb-2"
                                              id={val?.id}
                                              disabled={true}
                                              placeholder={
                                                (val?.FirstKey
                                                  ? val?.FirstKey + " "
                                                  : "") + val.FieldName
                                              }
                                            />
                                          </div>
                                        ) : (
                                          <Form.Control
                                            type={val?.type}
                                            className="kyc_form_body_input"
                                            id="FieldName"
                                            disabled={true}
                                            placeholder={
                                              (val?.FirstKey
                                                ? val?.FirstKey + " "
                                                : "") + val.FieldName
                                            }
                                          />
                                        )
                                      ) : val?.InputType == "select" ? (
                                        <div className="kyc_div_width">
                                          <Select
                                            options={val?.Options}
                                            placeholder={
                                              (val?.FirstKey
                                                ? val?.FirstKey + " "
                                                : "") + val.FieldName
                                            }
                                          />
                                        </div>
                                      ) : val.InputType == "PhoneInput" ? (
                                        <div className="kyc_ph_input kyc_div_width">
                                          <PhoneInput
                                            placeholder={
                                              (val?.FirstKey
                                                ? val?.FirstKey + " "
                                                : "") + val.FieldName
                                            }
                                            disabled={true}
                                          />
                                        </div>
                                      ) : val?.InputType == "Webcam" ? (
                                        <>
                                          <img
                                            src={noimg1}
                                            className="img-fluid imgset mb-2 borders"
                                          />
                                          <label className="kyc_form_body_label">
                                            (Live Image Will be Uploaded Here)
                                          </label>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <label className="kyc_form_body_label">
                            No Fields to Preview
                          </label>
                        )}

                        {/* <div className='mb-2'>
                                        <label className='kyc_form_body_label'>Input Two :</label>
                                        <Form.Control
                                            type="text"
                                            className="kyc_form_body_input"
                                            id="FieldName"
                                            placeholder='Input two'
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <label className='kyc_form_body_label'>Input Three :</label>
                                        <Form.Control
                                            type="text"
                                            className="kyc_form_body_input"
                                            id="FieldName"
                                            placeholder='Input three'
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <label className='kyc_form_body_label'>Input Four :</label>
                                        <Form.Control
                                            type="text"
                                            className="kyc_form_body_input"
                                            id="FieldName"
                                            placeholder='Input four'
                                        />
                                    </div>
                                    <div className='mb-2'>
                                        <label className='kyc_form_body_label'>Input Five :</label>
                                        <Form.Control
                                            type="text"
                                            className="kyc_form_body_input"
                                            id="FieldName"
                                            placeholder='Input five'
                                        />
                                    </div> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default KYCFieldSetting;
