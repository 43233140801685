import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import dummyimg from "../../assets/images/dummypf.png";
import { toast } from 'react-toastify';
import { UserAction, UserdetailGet } from '../../axioscalls/useaxios';
import config from '../../lib/config';

function Userdetail() {

    const navigate = useNavigate();
    const location = useLocation()
console.log('sefeffesfe---->',location.state);
    // const { detail }=props;
    const [userdata, Setuserdata] = useState(location.state)
    const [detail, Setdetails] = useState(location.state)
    // const detail = history.location.state

    useEffect(() => {

        fetch()

    }, [])
    const fetch = async () => {
        const res = await UserdetailGet({ id: userdata?._id })
        console.log('UserdetailGet_resp', res)
        if (res?.status) {
            Setdetails(res.data)
        }
        else {
            Setdetails({});
            toast.error(res?.msg)
        }
    }

    const deleteuser = async (data) => {

        let banstatus = {
            Id: data._id,
            action: "statuschange",
        }
        const response = await UserAction(banstatus)
        console.log('UserAction_response', response)
        if (response?.status) {
            toast.success(response.msg)
            fetch();
        } else {
            toast.error(response.msg)
        }
    }


    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                                <h5 className={`${styled.cardtitle}`}>USER DETAILS</h5>

                                <form>
                                    <div className={`row ${styled.formsec}`}>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>User Name</label>
                                            <input type="text" className={`formcontrol`}  disabled={true} placeholder='name' value={detail?.UserName} />
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Profile</label>

                                            {detail?.Profile == '' ? <img src={dummyimg} className={`${styled.dummyimg} d-block`} alt="Pnorofile" />
                                                : <img className={`${styled.dummyimg} d-block`} src={`${config.IMG_URL}/profile/${detail?._id}/${detail?.Profile}`} alt="Profile" />}
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Email</label>
                                            <input type="email" className={`formcontrol`}  disabled={true} value={detail?.EmailId} placeholder='para@gmail.com' />
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Profile Status</label>
                                            <input type="text" className={`formcontrol`} disabled={true} placeholder='status' value={detail?.Activate ? "Activate" : "De-Activated"} />
                                        </div>
                                    </div>
                                </form>

                                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`}  onClick={() => deleteuser(detail)}>{!detail?.Activate ? "Activate User" : "De-Activate User"}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </div>
    )
}

export default Userdetail