import React, { useState, Fragment } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {  WebhookAction, WithDrawSupportAction } from '../../axioscalls/useaxios';

function Addwebhook() {

  const navigate = useNavigate();
  const location = useLocation()

    let [ formdata, SetFormData ] = useState({})

    const handlechange = (e) => {
        e.preventDefault();
        const{id,value} = e.target;
      
        SetFormData({...formdata,[id]: value });
    }
    
    const handleSubmit = async() => {
        let senddata = {
            type: 'add',
            ...formdata,
        }
        let Resp = await WebhookAction(senddata);
        if(Resp?.status){
            toast.success("Added Successfully")
            navigate('/webhook')
        }
        else{
            toast.error("Try Again")
        }
        console.log('WebhookAction_resp',Resp)
    }

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>
              <div className={`${styled.tablesec}`}>
                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                <h5 className={`${styled.cardtitle}`}>Add webhook</h5>

                <form>
                  <div className={`row ${styled.formsec}`}>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>User Name</label>
                      <input type="text" id="Url" className={`formcontrol`} placeholder="Enter Url" value={formdata?.Url||''} onChange={(e) => handlechange(e)}  />
                    </div>
                    
                  </div>
                </form>

                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`} onClick={()=>handleSubmit()} >Submit</button>

              </div>
            </div>
          </div>
        </div>
      </Layout>

    </div>
  )
}

export default Addwebhook