import { lazy } from "react";
import Login from "../pages/loginpages/login.jsx";
import Users from "../pages/user/users.jsx";
import Userdetail from "../pages/user/userdetail.jsx";
import Kycdetail from "../pages/user/kycdetail.jsx";
import Supportticketlist from '../pages/supportticket/Supportticketlist.jsx';
import Supportticketdetail from "../pages/supportticket/Supportticketdetail.jsx";
import Chatlist from "../pages/chat/Chatlist.jsx";
import Chatdetail from '../pages/chat/Chatdetail.jsx'
import Chatsettings from "../pages/chat/Chatsettings.jsx";
import Depositrequestlist from "../pages/depositrequest/Depositrequestlist.jsx";
import Depostirequestview from "../pages/depositrequest/Depostirequestview.jsx";
import Withdrawrequestlist from "../pages/withdrawrequest/Withdrawrequestlist.jsx";
import Withdrawrequestdetail from "../pages/withdrawrequest/Withdrawrequestdetail.jsx";
import Reactiverequestlist from "../pages/reactivatesupport/Reactiverequestlist.jsx";
import Reactivatedetail from "../pages/reactivatesupport/Reactivatedetail.jsx";
import Namechangerequestlist from "../pages/namechangeaction/Namechangerequestlist.jsx";
import Namechangerequestdetail from "../pages/namechangeaction/Namechangerequestdetail.jsx";
import Webhooklist from "../pages/webhook/Webhooklist.jsx";
import Addwebhook from "../pages/webhook/Addwebhook.jsx";
import Editwebhook from "../pages/webhook/Editwebhook.jsx";
import KycSettings from "../pages/kyc/KycSettings.jsx";
import KYCFieldSetting from '../pages/kyc/Kyc_field_settings.jsx';
import AdminList from "../pages/admin/AdminList.jsx";
import AddAdmin from "../pages/admin/AddAdmin.jsx";
import Profilesettings from "../pages/profilesettings.jsx";

// module - based on this admin access restricted, login - for not login routes

const commonRoutes = [
    {
        path: "/",
        component: <Login />,
        name: '/login',
        module: 'login',
    },
    // {
    //     path: "/login/admin",
    //     component: lazy(() => import('../pages/loginpages/login.jsx')),
    //     name: '/login',
    //     module: 'login',
    // },
    // {
    //     path: "/forgotpasword",
    //     component: lazy(() => import('../loginpages/forgotpassword.js')),
    //     name: '/login',
    //     module: 'login',
    // },
    {
        path: "/users",
        component: <Users />,
        name: '/admin',
        module: 'user',
    },
    {
        path: "/userdetail",
        component: <Userdetail />,
        name: '/admin',
        module: 'user',
    },
    {
        path: "/kycdetail",
        component: <Kycdetail />,
        name: '/admin',
        module: 'user',
    },
    {
        path: "/kycsetting",
        component: <KycSettings />,
        name: '/admin',
        module: 'kycsetting',
    },
    {
        path: "/kycfieldsetting",
        component: <KYCFieldSetting />,
        name: '/admin',
        module: 'kycfieldsetting',
    },
    {
        path: "/webhook",
        component: <Webhooklist />,
        name: '/admin',
        module: 'webhook',
    },
    {
        path: "/addwebhook",
        component: <Addwebhook />,
        name: '/admin',
        module: 'webhook',
    },
    {
        path: "/editwebhook",
        component: <Editwebhook />,
        name: '/admin',
        module: 'webhook',
    },
    {
        path: "/supportticketlist",
        component: <Supportticketlist />,
        name: '/admin',
        module: 'supportticket',
    },
    {
        path: "/supportticketdetail",
        component: <Supportticketdetail />,
        name: '/admin',
        module: 'supportticket',
    },
    {
        path: "/reactivatelist",
        component: <Reactiverequestlist />,
        name: '/admin',
        module: 'reactivate',
    },
    {
        path: "/reactivatedetail",
        component: <Reactivatedetail />,
        name: '/admin',
        module: 'reactivate',
    },
    {
        path: "/namechangerequestlist",
        component: <Namechangerequestlist />,
        name: '/admin',
        module: 'namechange',
    },
    {
        path: "/namechangedetail",
        component: <Namechangerequestdetail />,
        name: '/admin',
        module: 'namechange',
    },
    {
        path: "/depositsupportlist",
        component: <Depositrequestlist />,
        name: '/admin',
        module: 'depositsupport',
    },
    {
        path: "/depositsupportdetail",
        component: <Depostirequestview />,
        name: '/admin',
        module: 'depositsupport',
    },
    {
        path: "/withdrawsupportlist",
        component: <Withdrawrequestlist />,
        name: '/admin',
        module: 'withdrawsupport',
    },
    {
        path: "/withdrawsupportdetail",
        component: <Withdrawrequestdetail />,
        name: '/admin',
        module: 'withdrawsupport',
    },
    {
        path: "/chatlist",
        component: <Chatlist />,
        name: '/admin',
        module: 'chat',
    },
    {
        path: "/chatdetail",
        component: <Chatdetail />,
        name: '/admin',
        module: 'chat',
    },
    {
        path: "/chatsettings",
        component: <Chatsettings />,
        name: '/admin',
        module: 'chatsetting',
    },
    {
        path: "/adminlist",
        component: <AdminList />,
        name: '/admin',
        module: 'admin',
    },
    {
        path: "/addadmin",
        component: <AddAdmin />,
        name: '/admin',
        module: 'admin',
    },
    {
        path: "/editadmin",
        component: <AddAdmin />,
        name: '/admin',
        module: 'admin',
    },
    {
        path: "/profile",
        component: <Profilesettings />,
        name: '/admin',
        module: 'profile',
    },
]


export default commonRoutes;
