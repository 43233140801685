import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
// import ReactDatatable from "react-data-table-component";
import styled from '../../assets/css/user.module.css';

import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { DepositSupportAction, GetUserList, WebhookAction, WithDrawSupportAction } from '../../axioscalls/useaxios';
import { Encryptdata } from '../../lib/encode_decode';

function Webhooklist() {


    const Wallet_Details = useSelector((state) => state.wallet_detail)

    var location = useLocation();
    const { pathname, state } = location;
    const path = pathname.split("/")[1]

    const [webhookList, setWebHookList] = useState([]);
    const [contents, setContents] = useState([]);

    const columns = [
        {
            key: "",
            name: "SNO",
            align: "left",
            sortable: true,
            cell: (record, index) =>
                <div>{index + 1}
                </div>
        },
        {
            key: "name",
            name: "Web Hook",
            className: "NFT IDT",
            align: "left",
            sortable: true,
            cell: rec =>
                <div >{rec?.Url}</div>

        },
        {
            key: "isAdmin",
            name: "Edit WebHook URL",
            className: "NFT IDT",
            align: "left",

            cell: record =>
                <div><Link to={{ pathname: `/editwebhook` }} state={{ id: Encryptdata(record?._id) }} ><button className=' allbtn allbtns' type='button'>Edit</button></Link></div>

        },

    ]



    useEffect(() => {
        faqdetails()
    }, [])

    const faqdetails = async () => {
        let senddata = { type: 'get', from: "webhook" };
        const resp = await WebhookAction(senddata);
        console.log("WebhookActionget_resp", resp)
        if (resp?.status)
            setWebHookList(resp.data);

    }


    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>

                            <div className={`${styled.tablesec}`}>
                                <h5 className={`${styled.cardtitle}`}>Web hooks list</h5>
                                <Link to="/addwebhook"><button type='button' className={`themebtn ${styled.addbtn} ${styled.mtb}`}>Add</button></Link>

                                {/* <div className={`row ${styled.formsec} ${styled.mtb}`}>
                  <div className={`col-xl-6 col-xxl-4 ms-auto`}>
                    <input
                      type="text"
                      className={'formcontrol'}
                      placeholder="Search"
                      value={filterText}
                      // onChange={handleSearch}
                      style={{ padding: '0.8vh', width: '100%' }}
                    />
                  </div>
                </div> */}

                                <DataTable
                                    className='react-datatables'
                                    columns={columns}
                                    data={webhookList}
                                    pagination // Enable pagination
                                    // paginationPerPage={2} // Rows per page
                                    paginationRowsPerPageOptions={[5, 10, 15, 20]} // Page options
                                />
                            </div>
                        </div>


                    </div>
                </div>

            </Layout>

        </div>
    )
}

export default Webhooklist