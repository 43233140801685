import React, { useEffect, useState } from 'react';
import cascading from '../assets/css/login.module.css'
import { FaEyeSlash, FaEye } from "react-icons/fa";
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NumberOnly, isEmpty } from '../lib/common';
import { toast } from 'react-toastify';
import { ForgotPasswordAction } from '../axioscalls/useaxios';
import config from '../lib/config';

function Forgotpwd({ setLoginstate }) {
    const [passwordType, setPasswordType] = useState("password");
    const [otp, setOtp] = useState('');
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    // var location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const Wallet_Details = useSelector((state) => state.wallet_detail)

    useEffect(() => {
        if (localStorage.getItem("adminlogin") == "yes") {
            navigate("/users");
        }
    }, [Wallet_Details.userAccountAddr])


    const initialValue = {
        "EmailId": "",
        "Password": "",
        "ConfirmPassword": "",
        "OTP": "",
    }


    const [formValue, setFormValue] = useState(initialValue);
    const [Page, SetPage] = useState('forgot')
    const [validErrors, setValidErrors] = useState({});
    const [DisableStatus, SetDisableStatus] = useState(false);


    const formvalidation = async (type) => {
        var validationErr = {};
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

        if (type == 'forgot' || type == 'resend') {
            if (formValue.EmailId == "") {
                validationErr.EmailId = "Enter Email-Id"
            }
            else if (formValue.EmailId != "") {
                if (!emailRegex.test(formValue.EmailId)) {
                    validationErr.EmailId = "Enter valid Email-Id"
                }
            }
        }
        else if (type == 'change') {
            if (isEmpty(formValue?.OTP)) {
                validationErr.OTP = "Enter OTP"
            }
            if (isEmpty(formValue?.Password)) {
                validationErr.Password = "Password cannot be empty"
            }
            else if (!config.PASSWORD.test(formValue?.Password)) {
                validationErr.PASSWORD = "Password must contain alphanumeric, must contain 8 to 15 letters"
            }
            if (isEmpty(formValue?.ConfirmPassword)) {
                validationErr.ConfirmPassword = "Enter Confirm Password"
            }
            else if (formValue?.ConfirmPassword != formValue?.Password) {
                validationErr.ConfirmPassword = "Password and Confirm Password Must be Same."
            }
        }
        return validationErr;

    }


    const handleSubmit = async (type) => {
        if (!DisableStatus) {
            let toastid = toast.loading(type == 'resend' ? "Resending..." : "Validating...")
            SetDisableStatus(true)
            let data = { action: type };
            let errors = await formvalidation(type);

            if (errors) setValidErrors(errors);
            console.log('errors---->', errors);
            if (!isEmpty(errors)) {
                toast.update(toastid, { render: "Fix all validations", type: 'error', autoClose: 1000, isLoading: false });
            }
            else {
                data.EmailId = formValue?.EmailId;
                if (type == 'change') {
                    data.OTP = formValue?.OTP;
                    data.Password = formValue?.Password;
                }
                var resp = await ForgotPasswordAction(data);
                console.log('ForgotPasswordAction_resp', resp)
                if (resp?.status) {
                    toast.update(toastid, { render: resp.msg, type: 'success', autoClose: 1000, isLoading: false });
                    // toast.success(resp.msg)
                    if (type == 'change') {
                        navigate("/");
                    }
                    else {
                        SetPage("change");
                    }
                }
                else {
                    toast.update(toastid, { render: resp.msg, type: 'error', autoClose: 1000, isLoading: false });
                    // toast.error(resp?.msg)
                    if (resp?.data) {
                        setValidErrors(resp?.data);
                    }
                }
            }
            SetDisableStatus(false)
        }
    }


    const handlechange = (e) => {

        const { id, value } = e.target;
        setFormValue({ ...formValue, [id]: value })

    }


    return (
        <div className={`${cascading.logincard}`}>
            <p className={`${cascading.headtext}`}>Email Verification</p>

            <form>
                <div className={`row ${cascading.formsec}`}>
                    {Page == 'forgot'
                        ?
                        <div className={`col-12 ${cascading.mbspace}`}>
                            <div>
                                <label for="exampleInputEmail1" className={`form-label`} >Email</label>
                                <input type="email" className={`form-control`} id="EmailId" value={formValue.EmailId} onChange={(e) => handlechange(e)} placeholder='Email' aria-describedby="emailHelp" />
                                <span className='error_msg'>{validErrors?.EmailId}</span>
                            </div>
                            <div className='text-center'>
                                <button type="button" className={`btn themebtn ${cascading.logbtn} ${cascading.mbtopspace}`} onClick={() => handleSubmit('forgot')}>Send Otp</button>

                            </div>
                        </div>
                        :
                        <>

                            <div className={`col-12  ${cascading.mbspace} ${cascading.otpinput}`}>
                                <div className={`text-center ${cascading.otpss}`}>
                                    <label for="exampleInputEmail1" className={`form-label`}>Enter Otp</label>
                                    <OtpInput
                                        value={formValue?.OTP}
                                        onChange={(e) => setFormValue({ ...formValue, OTP: NumberOnly(e) })}
                                        numInputs={4}
                                        className={` ${cascading.otpinput}`}
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <p className={`text-dark ${cascading.sendotp}`} onClick={() => handleSubmit('resend')} >Resend OTP </p>
                                </div>
                            </div>
                            <span className='error_msg'>{validErrors?.OTP}</span>
                            <div className={`col-12 ${cascading.mbspace}`}>
                                <label for="exampleInputEmail1" className={`form-label`}>Password</label>
                                <div class="input-group ">

                                    <input type={passwordType} className={`form-control`} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" id="Password" value={formValue.Password} onChange={(e) => handlechange(e)} />
                                    <span className={`input-group-text ${cascading.eyesec}`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span>
                                </div>
                                <span className='error_msg'>{validErrors.Password}</span>
                            </div>
                            <div className={`col-12 ${cascading?.mbspace}`}>
                                <label for="exampleInputEmail1" className={`form-label`}>Confirm Password</label>
                                <div class="input-group ">

                                    <input type={passwordType} className={`form-control`} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" id="ConfirmPassword" value={formValue.ConfirmPassword} onChange={(e) => handlechange(e)} />
                                    <span className={`input-group-text ${cascading.eyesec}`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span>
                                    <span className='error_msg'>{validErrors?.ConfirmPassword}</span>
                                </div>
                            </div>
                            <div className={`col-12 text-center ${cascading.mbtopspace}`}>
                                <button className={`btn themebtn ${cascading.logbtn}`} onClick={() => handleSubmit('change')}>Submit</button>
                            </div>
                        </>
                    }

                </div>
            </form>
            <div className={`text-center ${cascading.mbtopspace}`}>
                <p className={`${cascading.forgottext}`} onClick={setLoginstate}>Back to Login</p>

            </div>




        </div>
    )
}

export default Forgotpwd