import CommonFunction from "../shared/commonfunction";
import Config from "../lib/config";
import { Decryptdata, Encryptdata } from "./encode_decode";
import axios from 'axios';
import React from "react";

let commonfunc = null;

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};
export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};

export const axiosFunc = async (data) => {
  try{
    let Resp    =   {};
    // if(data?.url?.includes('/user/test')){
      // if(false){
        if(data?.params){
          data.params = { data: Encryptdata(data.params)  }
        }
        else if(data?.data){
          if(!(data?.data instanceof FormData)){
            data.data = { data: Encryptdata(data.data)  }
          }
        }
      // }
      
    // }
    data.headers = { Authorization : GetCookie() };
      Resp    =   await axios(data)
      
      if(Resp?.data){
          Resp.data = isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data)
      }
      if(Resp?.data?.status == false && !isEmpty(Resp?.data?.action) ){
        if(Resp?.data?.action?.navigate){
          commonfunc.Redirect(Resp?.data?.action?.redirectpage,(Resp?.data?.msg?Resp?.data?.msg:"Not Allowed"));
        }
        else if(Resp?.data?.action?.logout){
          commonfunc.LogOut(Resp?.data?.msg?Resp?.data?.msg:"Not Allowed");
        }
      }

      return Resp
  }
  catch (e) {
    console.log('axiosFunc error',e)
    return { data: {success: 'error', msg: "Axios Error", data: null } }
  }
}

export const FirstCaps = (data) => {
  try{
    return data[0].toLocaleUpperCase() + data.slice(1);
  }
  catch(err){
    console.log('FirstCaps_error',err)
    return data
  }
};

export const NumberChange = (data) => {
  try{
    return (isNaN(Number(data)) ? 0 : Number(data))
  }
  catch(err){
    console.log('NumberChange_error',err)
    return 0;
  }
}

export const MinuteHourFormat = (data) => {
  data = NumberChange(isEmpty(data) ? 0 : data);
  return (data < 10 ? "0" : "") + data;
};

export const GetCookie = () => {
  try{
    const usertoken = ("; " + document.cookie)
		.split(`; admintoken=`)
		.pop()
		.split(";")[0];
    return usertoken;
  }
  catch(err){
    console.log('GetCookie_error',err)
  }
}

export const ImgEmpty = (data) => {
  try {
    return (
      (typeof (data) == 'object' && !data?.type) ||
      data === undefined ||
      data == "undefined" ||
      data === null ||
      (typeof data === 'string' && data.trim().length === 0) ||
      (typeof data === 'string' && data === '0') ||
      (typeof data === 'number' && data === 0)
    )
  }
  catch(err){
    console.log('ImgEmpty_error',err)
    return false
  }
}

export const ImgUrl = (data) => {
  try{
    return URL.createObjectURL(data);
  }
  catch(err){
    console.log('ImgUrl_error',err)
    return ''
  }
}

export function UseHook () {
  commonfunc = CommonFunction()
  return(
    <></>
  )
}

export const ShortString = ( text, outputlength ) => {
  try{
    if(typeof(text)=='string'){
      outputlength = outputlength ? outputlength : text.length;
      if(text.length > outputlength){
        let crtlength = (outputlength - 3) / 2
        return (text.substring(0,crtlength)+"..."+text.substring(text.length-crtlength,text.length))
      }
      return text;
    }
    else{
      return text;
    }
  }
  catch(err){
    console.log('ShortString_error',err)
    return text
  }
}
