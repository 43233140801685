import React, { useEffect, useState } from 'react'
import cascading from '../../assets/css/login.module.css'
import Images from '../../assets/images/images'
import { FaEyeSlash, FaEye } from "react-icons/fa";
import Forgotpwd from '../../components/forgotpwd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAdmin } from '../../axioscalls/useaxios';
import { Account_Connect } from '../../redux/action';
import { toast } from 'react-toastify';
import { isEmpty } from '../../lib/common';
function Login() {

    // var location = useLocation();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const Wallet_Details = useSelector((state) => state.wallet_detail)

    useEffect(() => {
        if (localStorage.getItem("adminlogin") == "yes") {
            navigate("/users");
        }
    }, [Wallet_Details.userAccountAddr])


    const initialValue = {
        "EmailId": "",
        "Password": ""
    }


    const [formValue, setFormValue] = useState(initialValue);
    const [validErrors, setValidErrors] = useState("");
    const [loc, setLoc] = useState("")


    const formvalidation = async (data) => {

        var validationErr = {};
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;

        if (data.EmailId == "") { validationErr.EmailId = "Email cannot be empty" }
        else if (data.EmailId != "") {
            if (!emailRegex.test(data.EmailId)) { validationErr.EmailId = "Enter valid EmailId" }
        }

        if (!data.Password) { validationErr.Password = "Password cannot be empty" }
        return validationErr;

    }




    const handleSubmit = async () => {
        var data = {
            "EmailId": formValue.EmailId,
            "Password": passwordInput
        }
        var resp = await formvalidation(data);

        if (resp) setValidErrors(resp)
        if (!isEmpty(resp)) {

        }
        else {
            data.path = "login";
            var resp = await LoginAdmin(data);
            console.log('LoginAdmin_resp', resp)
            if (resp?.data) {
                localStorage.setItem("adminlogin", "yes")
                document.cookie = "admintoken=" + resp.token;
                dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                        payload: resp?.data,
                        loggedin: 'true',
                        token: 'skip',
                    }
                });
                toast.success(resp.msg)
                navigate("/users");
            }
            else (toast.error(resp?.msg))

        }


    }


    const handlechange = (e) => {

        const { id, value } = e.target;
        setFormValue({ ...formValue, [id]: value })

    }

    const [passwordType, setPasswordType] = useState("Password");
    const [passwordInput, setPasswordInput] = useState("");
    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }
    const togglePassword = () => {
        if (passwordType === "Password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("Password")
    }


    const InitialForm = {

        email: "",
        password: ""
    }
    const [loginstate, setLoginstate] = useState(true)

    return (

        <div className={`${cascading.loginpage}`}>
            <div className={`container ${cascading.customcontainer}`}>
                <div className={`row d-flex alighn-items-center justify-content-center`}>

                    <div className={`col-lg-4`}>
                        {loginstate ?
                            <div className={`${cascading.logincard}`}>
                                <div className={`text-center mb-3`}>
                                    <img src={Images.logo} className={`img-fluid ${cascading.logoimg}`} alt='logoimg' />
                                </div>
                                <div>
                                    <div className={`row ${cascading.formsec}`}>
                                        <div className={`col-12 ${cascading.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`form-label`}>Email</label>
                                            <input type="email" className={`form-control`} id="EmailId"  placeholder='Email' aria-describedby="emailHelp"  value={formValue.EmailId} onChange={(e) => handlechange(e)}/>
                                        </div>
                                        <div className={`col-12 ${cascading.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`form-label`}>Password</label>
                                            <div class="input-group ">

                                                <input type={passwordType} onChange={(e)=>handlePasswordChange(e)}  className={`form-control`} placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                                                <span className={`input-group-text ${cascading.eyesec}`} onClick={() => togglePassword()}>  {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}</span>
                                            </div>
                                        </div>
                                        <div className={`col-12 text-center ${cascading.mbtopspace}`}>
                                            <button onClick={() => handleSubmit()} className={`btn themebtn ${cascading.logbtn}`}>Login</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={`text-center ${cascading.mbtopspace}`}>
                                    <p className={`${cascading.forgottext}`} onClick={() => setLoginstate(false)}>Forgot Password</p>

                                </div>




                            </div> :
                            <>
                                <Forgotpwd setLoginstate={() => setLoginstate(true)} />

                            </>
                        }

                    </div>

                </div>

            </div>


        </div>
    )
}

export default Login