const EnvName = "clientdemo";

let key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.SECRET_KEY = "EDAFACENFTMarketPlace#%^09276!!";
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;
key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;

if (EnvName == "clientdemo") {
  key.COMMON_URL = "https://backend.edaface.com/common";
  key.BACK_URL = "https://backend.edaface.com/front";
  key.AdminAPI = "https://backend.edaface.com/admin";
  key.CHATAPI = `https://backend.edaface.com/chat`;
  key.IMG_URL = "https://backend.edaface.com";
} else {
  key.COMMON_URL = "http://localhost:9376/common";
  key.BACK_URL = "http://localhost:9376/front";
  key.AdminAPI = "http://localhost:9376/admin";
  key.CHATAPI = `http://localhost:9376/chat`;
  key.IMG_URL = "http://localhost:9376";
  key.cookiename = "ssoToken";
  key.domainname = "localhost";
}

export default key;
