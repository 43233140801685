import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Account_Connect } from "../redux/action";
import { toast } from "react-toastify";

export default function CommonFunction () {

    const dispatch = useDispatch();
    const Wallet_Details = useSelector((state) => state.wallet_detail)
    const history = useNavigate();

    const LogOut = (msg) => {
        localStorage.removeItem('token');
    	localStorage.removeItem('walletConnectType');
		localStorage.setItem("adminlogin", "no");
    	document.cookie = "admintoken" + "= ; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        dispatch({
            type: Account_Connect,
            Account_Detail: {
                loggedin: 'false',
                token: '',
            }
        })
        toast.error(msg)
        return history('/');
    }

    const Redirect = (redirectpage,msg) => {
        toast.error(msg)
        return history(redirectpage?redirectpage:'/user');
    }

    return(
        {
            LogOut,
            Redirect,
        }
    )
}