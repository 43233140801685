import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
// import ReactDatatable from "react-data-table-component";
import styled from '../../assets/css/user.module.css';

import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { GetUserList } from '../../axioscalls/useaxios';

function Users() {



  var location = useLocation();
  const Wallet_Details = useSelector((state) => state.wallet_detail)

  const{pathname,state}=location;
  const path = pathname.split("/")[1]
  

  const [userList,setUserList] = useState([])




const [columns, SetColumns] = useState([
  {
    key: "",
    name: "SNO",
    className: "NFT",
    align: "left",
    sortable: true,
    cell: (record,index) =>
    <div>{index+1}
    </div>

  },
  {
    key: "Email",
    name: "Email Address",
    className: "NFT",
    align: "left",
    cell:rec=>
      <div title={rec.EmailId}>{rec.EmailId}</div>
  }, 
  {
    key: "UserName",
    name: "Name",
    className: "NFT",
    align: "left",
    cell:rec=>
    <div title={rec.FirstName}>{ (rec.UserName?.length > 13 ? rec.UserName.toString().slice(0,5)+'...'+rec.UserName.toString().slice(-5) : rec?.UserName) }</div>
  },
  {
    key: "Activate",
    name: "Status",
    className: "NFT",
    align: "left",
    cell:rec=>
    <div title={rec.Activate}>{ rec?.Activate ? "Activate" : "De-Activated" }</div>
  },
])

  useEffect(()=>{
      getUsers();
  },[])

  useEffect(()=>{
    if(Wallet_Details?.payload?.Permission?.user || Wallet_Details?.payload?.Role == "superadmin"){
      SetColumns([
        {
          key: "",
          name: "SNO",
          className: "NFT",
          align: "left",
          sortable: true,
          cell: (record,index) =>
          <div>{index+1}
          </div>
      
        },
        {
          key: "Email",
          name: "Email Address",
          className: "NFT",
          align: "left",
          cell:rec=>
            <div title={rec.EmailId}>{rec.EmailId}</div>
        }, 
        {
          key: "UserName",
          name: "Name",
          className: "NFT",
          align: "left",
          cell:rec=>
          <div title={rec.FirstName}>{ (rec.UserName?.length > 13 ? rec.UserName.toString().slice(0,5)+'...'+rec.UserName.toString().slice(-5) : rec?.UserName) }</div>
        },
        {
          key: "Activate",
          name: "Status",
          className: "NFT",
          align: "left",
          cell:rec=>
          <div title={rec.Activate}>{ rec?.Activate ? "Activate" : "De-Activated" }</div>
        },
        {
          name:"Detail",
          cell: record =>
          <div><Link to={{pathname:`/userdetail`}} state={record} ><button className=' allbtn'>view</button></Link></div>
      
        },
      
        {
          name:"KYC Detail",
          cell: record =>
          <div><Link to={{pathname:`/kycdetail`}} state={record} ><button className=' allbtn'>KYC view</button></Link></div>
      
        },
      ])
    }
  },[Wallet_Details?.payload])

  const getUsers = async()=>{
    const resp = await GetUserList();
    console.log("GetUserList_resp",resp);
      // if(resp.data){
      //   setUserList(resp?.data);
      // }

      if(resp?.status){
        setUserList(resp?.data);
      }
  }


  const [filterText, setFilterText] = useState('');
  const [filteredData, setFilteredData] = useState();

  // const handleSearch = (event) => {
  //   const value = event.target.value;
  //   setFilterText(value);

  //   const filtered = data.filter(item =>
  //     Object.values(item).some(val =>
  //       String(val).toLowerCase().includes(value.toLowerCase())
  //     )
  //   );
  //   setFilteredData(filtered);
  // };

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>

              <div className={`${styled.tablesec}`}>
                <h5 className={`${styled.cardtitle}`}>USER LIST</h5>

            
                {/* <div className={`row ${styled.formsec} ${styled.mtb}`}>
                  <div className={`col-xl-6 col-xxl-4 ms-auto`}>
                    <input
                      type="text"
                      className={'formcontrol'}
                      placeholder="Search"
                      value={filterText}
                      // onChange={handleSearch}
                      style={{ padding: '0.8vh', width: '100%' }}
                    />
                  </div>
                </div> */}

                <DataTable
                  className='react-datatables'
                  columns={columns}
                  data={userList}
                  pagination // Enable pagination
                  // paginationPerPage={2} // Rows per page
                  paginationRowsPerPageOptions={[5, 10, 15, 20]} // Page options
                />
              </div>
            </div>


          </div>
        </div>

      </Layout>

    </div>
  )
}

export default Users