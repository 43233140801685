import React, { useState, useRef, useEffect } from 'react'
import Layout from '../../components/layout'
import { Button, Form, FormControl } from "react-bootstrap";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { editorOptions } from '../../components/editoroptions';
import { GetChatSetting, UpdateChatSetting } from '../../axioscalls/useaxios';
import { MinuteHourFormat, NumberChange, isEmpty } from '../../lib/common';
import { toast } from 'react-toastify';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { FaTrash } from "react-icons/fa";

function Chatsettings() {

    const navigate = useNavigate();

    const editorRef = useRef();
    const contentRef = useRef();
    const [value, setValue] = useState("");

    const [Errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [answer, SetAnswer] = useState(null);

    useEffect(() => {
        console.log(editorRef.current?.editor);
    }, []);

    useEffect(() => {
        if (!contentRef.current) return;
        contentRef.current.innerHTML = answer;
    }, [answer]);

    const onChangeHandler = (content) => {
        console.log(content);
        setValue(content);
    };





    const initdaytiming = {
        Sunday: {
            day: "Sunday",
            Timing: {
                day: "Sunday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Monday: {
            day: "Monday",
            Timing: {
                day: "Monday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Tuesday: {
            day: "Tuesday",
            Timing: {
                day: "Tuesday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Wednesday: {
            day: "Wednesday",
            Timing: {
                day: "Wednesday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Thursday: {
            day: "Thursday",
            Timing: {
                day: "Thursday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Friday: {
            day: "Friday",
            Timing: {
                day: "Friday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
        Saturday: {
            day: "Saturday",
            Timing: {
                day: "Saturday",
                Status: false,
                hours: false,
                error: "",
                timing: [],
            },
        },
    };
    const [DayTimings, setDayTimings] = useState(initdaytiming);
    const [HourMinuteDisplay, SetHourMinuteDisplay] = useState({
        Hours: [
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 22, 23,
        ],
        Minutes: [1, 2, 3, 4],
    });
    var [selectCheck, SetSelectCheck] = useState(true);
    const [ChooseTimeArray, SetChooseTimeArray] = useState([1]);
    const [SelectedTime, SetSelectedTime] = useState({
        Timing: [
            {
                EndTime: "24:00",
                EndTimeshow: new Date(new Date().setHours(23, 59, 59)),
                StartTime: "00:00",
                StartTimeshow: new Date(new Date().setHours(0, 0, 0)),
            },
        ],
    });


    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        const resp = await GetChatSetting();
        console.log("GetChatSetting_resp", resp);
        setFormData({ ...resp?.data });
        let respdaytiming = {
            ...(resp?.data?.Timings ? resp?.data?.Timings : {}),
        };
        SetSelectCheck(false);
        SetAnswer(resp?.data?.defaulttext ? resp?.data?.defaulttext : "");
        if (Object.keys(respdaytiming)?.length > 0) {
            Object.keys(respdaytiming).map((val) => {
                let currval = { ...(respdaytiming[val] ? respdaytiming[val] : {}) };
                respdaytiming[val] = {};
                respdaytiming[val].Timing = currval;
                respdaytiming[val].day = val;
            });
            let instobj = { Timing: [] };
            let days = Object.keys(respdaytiming);
            Object.values(respdaytiming).map((val, ind) => {
                Object.keys(val).map((type) => {
                    if (
                        (type == "Delivery" || type == "Timing" || type == "Pickup") &&
                        val?.[type]?.Status
                    ) {
                        if (val?.[type]?.timing?.length > 0) {
                            val[type].timing.map((timeval) => {
                                if (
                                    instobj?.[type]?.find(
                                        (findcheck) =>
                                            findcheck?.EndTime == timeval?.EndTime &&
                                            findcheck?.StartTime == timeval?.StartTime
                                    )
                                ) {
                                    let findindex = instobj[type].findIndex(
                                        (findcheck) =>
                                            findcheck?.EndTime == timeval?.EndTime &&
                                            findcheck?.StartTime == timeval?.StartTime &&
                                            !isEmpty(findcheck?.EndTime) &&
                                            !isEmpty(findcheck?.StartTime)
                                    );
                                    instobj[type] = [
                                        ...instobj[type].slice(0, findindex),
                                        ...[
                                            {
                                                ...instobj[type]?.[findindex],
                                                ...{
                                                    [days[ind]]: true,
                                                },
                                            },
                                        ],
                                        ...instobj[type].slice(findindex + 1, instobj[type].length),
                                    ];
                                } else {
                                    instobj[type].push({
                                        StartTime: timeval?.StartTime,
                                        StartTimeshow: HourMinutetoTime(
                                            timeval?.StartHour,
                                            timeval?.StartMinute
                                        ),
                                        EndTime: timeval?.EndTime,
                                        EndTimeshow: HourMinutetoTime(
                                            timeval?.EndHour,
                                            timeval?.EndMinute
                                        ),
                                        [days[ind]]: true,
                                    });
                                }
                            });
                        }
                    }
                });
            });
            setDayTimings(respdaytiming);
            SetSelectedTime({ ...instobj });
        }
        setTimeout(()=>{
            SetSelectCheck(true);
        },[1])
        
    };

    const validation = () => {
        let error = {};
        if (!Object.values(DayTimings).some((val) => val?.Timing?.Status)) {
            error.Timing = "Select Timing for Availability";
        }
        if (isEmpty(formData?.initialtext)) {
            error.initialtext = "Enter Initial Text";
        }
        if (isEmpty(answer)) {
            error.answer = "Enter Default Content";
        }
        return error;
    };

    const MergeTiming = () => {
        let errors;
        let curdaytiming = DayTimings;
        let currTiming = SelectedTime?.Timing;
        Object.keys(SelectedTime).map((timetype) => {
            if (SelectedTime?.[timetype]?.length > 0) {
                SelectedTime[timetype].map((val, ind) => {
                    if (isEmpty(val?.StartTime)) {
                        errors[timetype + "StartTime" + ind] = "Enter Start-Time";
                    }
                    if (isEmpty(val?.EndTime)) {
                        errors[timetype + "EndTime" + ind] = "Enter Start-Time";
                    }
                    if (timetype != "Timing") {
                        // let error = true;
                        Object.keys(val).map((keys) => {
                            if (
                                keys != "EndTime" &&
                                keys != "EndTimeshow" &&
                                keys != "StartTime" &&
                                keys != "StartTimeshow" &&
                                val[keys]
                            ) {
                                if (curdaytiming?.[keys]?.Timing?.timing?.length > 0) {
                                    curdaytiming[keys].Timing.timing.map((checktime) => {
                                        // if( error && (HourMinutetoTime(checktime?.EndHour,checktime?.EndMinute)>=HourMinutetoTime(val?.EndTime.split(':')[0],val?.EndTime.split(':')[1])) && (HourMinutetoTime(val?.StartHour,val?.StartMinute)>=HourMinutetoTime(checktime?.StartHour,curdaytiming?.StartMinute))){
                                        //   error = false;
                                        // }
                                    });
                                }

                                // console.log('lkjskjfsfs',val,timetype,SelectedTime[timetype],Object.keys(val),curdaytiming?.[keys]?.[timetype])
                            }
                            // else if(Object.keys(val)?.every((testkeys)=>(testkeys!='EndTime' || testkeys!='EndTimeshow' || testkeys!='StartTime' || testkeys!='StartTimeshow'))){
                            //   error = false;
                            // }
                        });
                        // console.log('kjshjkhsfksfs',timetype,val,error,errors,Object.keys(val)?.every((testkeys)=>(testkeys!='EndTime' || testkeys!='EndTimeshow' || testkeys!='StartTime' || testkeys!='StartTimeshow')),)
                        // if(error){
                        //     errors[timetype+'EndTime'+ind] = "Choose Time between Shop-Timing";
                        //     errors[timetype+'StartTime'+ind] = "Choose Time between Shop-Timing";
                        // }
                    }
                });
            }
        });
        if (isEmpty(errors)) {
            // errors[type] = false;
            errors = { ...errors, Delivery: false, Timing: false, Pickup: false };
            Object.values(curdaytiming).map((it, ind) => {
                Object.keys(it).map((type) => {
                    if (type == "Timing") {
                        if (it?.[type]?.timing?.length > 1) {
                            it[type].timing.map((checkdata, checkdataind) => {
                                it[type].timing.map((checkdata2, checkdata2ind) => {
                                    if (checkdata2ind != checkdataind) {
                                        if (
                                            HourMinutetoTime(
                                                checkdata?.StartHour,
                                                checkdata?.StartMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata2?.StartHour,
                                                checkdata2?.StartMinute
                                            ) &&
                                            HourMinutetoTime(
                                                checkdata?.EndHour,
                                                checkdata?.EndMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata2?.EndHour,
                                                checkdata2?.EndMinute
                                            ) &&
                                            HourMinutetoTime(
                                                checkdata2?.StartHour,
                                                checkdata2?.StartMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata?.EndHour,
                                                checkdata?.EndMinute
                                            )
                                        ) {
                                            let newstarttime =
                                                HourMinutetoTime(
                                                    checkdata?.StartHour,
                                                    checkdata?.StartMinute
                                                ) <
                                                    HourMinutetoTime(
                                                        checkdata2?.StartHour,
                                                        checkdata2?.StartMinute
                                                    )
                                                    ? checkdata?.StartTime
                                                    : checkdata2?.StartTime;
                                            let newendtime =
                                                HourMinutetoTime(
                                                    checkdata?.EndHour,
                                                    checkdata?.EndMinute
                                                ) <
                                                    HourMinutetoTime(
                                                        checkdata2?.EndHour,
                                                        checkdata2?.EndMinute
                                                    )
                                                    ? checkdata2?.EndTime
                                                    : checkdata2?.EndTime;
                                            curdaytiming = {
                                                ...curdaytiming,
                                                ...{
                                                    [it.day]: {
                                                        ...curdaytiming[it.day],
                                                        ...{
                                                            [type]: {
                                                                ...curdaytiming[it.day][type],
                                                                ...{
                                                                    timing: [
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            0,
                                                                            checkdataind < checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind
                                                                        ),
                                                                        ...[
                                                                            {
                                                                                ...curdaytiming[it.day][type]?.timing?.[
                                                                                checkdataind < checkdata2ind
                                                                                    ? checkdataind
                                                                                    : checkdata2ind
                                                                                ],
                                                                                ...{
                                                                                    StartHour: newstarttime.split(":")[0],
                                                                                    StartMinute:
                                                                                        newstarttime.split(":")[1],
                                                                                    StartTime: newstarttime,
                                                                                    EndHour: newendtime.split(":")[0],
                                                                                    EndMinute: newendtime.split(":")[1],
                                                                                    EndTime: newendtime,
                                                                                },
                                                                            },
                                                                        ],
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            (checkdataind < checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind) + 1,
                                                                            checkdataind > checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind
                                                                        ),
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            (checkdataind > checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind) + 1,
                                                                            curdaytiming[it.day][type]?.timing?.length
                                                                        ),
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    },
                                                },
                                            };
                                        } else if (
                                            HourMinutetoTime(
                                                checkdata2?.StartHour,
                                                checkdata2?.StartMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata?.StartHour,
                                                checkdata?.StartMinute
                                            ) &&
                                            HourMinutetoTime(
                                                checkdata2?.EndHour,
                                                checkdata2?.EndMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata?.EndHour,
                                                checkdata?.EndMinute
                                            ) &&
                                            HourMinutetoTime(
                                                checkdata?.StartHour,
                                                checkdata?.StartMinute
                                            ) <=
                                            HourMinutetoTime(
                                                checkdata2?.EndHour,
                                                checkdata2?.EndMinute
                                            )
                                        ) {
                                            let newstarttime =
                                                HourMinutetoTime(
                                                    checkdata?.StartHour,
                                                    checkdata?.StartMinute
                                                ) <
                                                    HourMinutetoTime(
                                                        checkdata2?.StartHour,
                                                        checkdata2?.StartMinute
                                                    )
                                                    ? checkdata?.StartTime
                                                    : checkdata2?.StartTime;
                                            let newendtime =
                                                HourMinutetoTime(
                                                    checkdata?.EndHour,
                                                    checkdata?.EndMinute
                                                ) <
                                                    HourMinutetoTime(
                                                        checkdata2?.EndHour,
                                                        checkdata2?.EndMinute
                                                    )
                                                    ? checkdata2?.EndTime
                                                    : checkdata?.EndTime;

                                            curdaytiming = {
                                                ...curdaytiming,
                                                ...{
                                                    [it.day]: {
                                                        ...curdaytiming[it.day],
                                                        ...{
                                                            [type]: {
                                                                ...curdaytiming[it.day][type],
                                                                ...{
                                                                    timing: [
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            0,
                                                                            checkdataind < checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind
                                                                        ),
                                                                        ...[
                                                                            {
                                                                                ...curdaytiming[it.day][type]?.timing?.[
                                                                                checkdataind < checkdata2ind
                                                                                    ? checkdataind
                                                                                    : checkdata2ind
                                                                                ],
                                                                                ...{
                                                                                    StartHour: newstarttime.split(":")[0],
                                                                                    StartMinute:
                                                                                        newstarttime.split(":")[1],
                                                                                    StartTime: newstarttime,
                                                                                    EndHour: newendtime.split(":")[0],
                                                                                    EndMinute: newendtime.split(":")[1],
                                                                                    EndTime: newendtime,
                                                                                },
                                                                            },
                                                                        ],
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            (checkdataind < checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind) + 1,
                                                                            checkdataind > checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind
                                                                        ),
                                                                        ...curdaytiming[it.day][
                                                                            type
                                                                        ]?.timing?.slice(
                                                                            (checkdataind > checkdata2ind
                                                                                ? checkdataind
                                                                                : checkdata2ind) + 1,
                                                                            curdaytiming[it.day][type]?.timing?.length
                                                                        ),
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    },
                                                },
                                            };
                                        }
                                    }
                                });
                            });
                        }
                        if (curdaytiming[it.day][type]?.timing?.length == 0) {
                            curdaytiming = {
                                ...curdaytiming,
                                ...{
                                    [it.day]: {
                                        ...curdaytiming[it.day],
                                        ...{
                                            [type]: {
                                                ...curdaytiming[it.day][type],
                                                ...{
                                                    Status: false,
                                                },
                                            },
                                        },
                                    },
                                },
                            };
                        }
                        if (curdaytiming[it.day][type]?.timing?.length > 0) {
                            errors[type] = true;
                        }
                    }
                });
            });
            Object.keys(curdaytiming).map((instkeys) => {
                curdaytiming[instkeys] = curdaytiming[instkeys].Timing;
            });

            return { ...{ newdaytiming: curdaytiming }, ...errors };
        }
        return errors;
    };

    const AddSeconds = (data) => {
        try {
            if (data) {
                data = { ...data };
                Object.keys(data).map((val) => {
                    if (data?.[val]?.timing?.length > 0) {
                        data[val].timing.map((timeval) => {
                            timeval.StartSecond =
                                NumberChange(timeval?.StartHour) * 3600 +
                                NumberChange(timeval?.StartMinute) * 60;
                            timeval.EndSecond =
                                NumberChange(timeval?.EndHour) * 3600 +
                                NumberChange(timeval?.EndMinute) * 60;
                        });
                    }
                });
            }
            return data;
        } catch (err) {
            console.log("AddSeconds_error", err);
            return data;
        }
    };

    const onEditorChange = (evt) => {
        // var description_text = evt.editor.getData();
        SetAnswer(evt);
    };

    const OnChange = (e) => {
        let id = e?.target?.id;
        let value = e?.target?.value;
        setFormData({
            ...formData,
            ...{
                [id]: value,
            },
        });
    };

    const onSubmit = async () => {
        const err = validation();
        if (!isEmpty(err)) return setErrors(err);
        setErrors({});
        let mergetiming = MergeTiming();
        let newtiming = AddSeconds(mergetiming?.newdaytiming);
        const sendData = {
            Timings: mergetiming?.newdaytiming,
            defaulttext: answer,
            initialtext: formData?.initialtext,
        };

        const resp = await UpdateChatSetting(sendData);
        if (resp?.status) {
            toast.success(resp?.msg);
        } else {
            toast.error(resp?.msg);
        }
        navigate("/chatlist")
    };

    const OnChangeTime = (e, index, day, type, timingtype) => {
        try {
            // console.log('OnChangeTime called')
            setErrors({});
            let setvalue = {};
            if (type == "checkbox") {
                setvalue = {
                    ...SelectedTime,
                    ...{
                        [timingtype]: [
                            ...SelectedTime?.[timingtype]?.slice(0, index),
                            ...[
                                {
                                    ...SelectedTime?.[timingtype][index],
                                    ...{
                                        [day]: e?.target?.checked,
                                    },
                                },
                            ],
                            ...SelectedTime?.[timingtype]?.slice(
                                index + 1,
                                SelectedTime?.[timingtype]?.length
                            ),
                        ],
                    },
                };
                // SetSelectedTime(setvalue)
            } else if (type == "time") {
                e._d = new Date(e._d.setSeconds(0, 0));
                if (day == "EndTime") {
                    if (SelectedTime?.[timingtype]?.[index]?.StartTimeshow >= e._d) {
                        toast.error("Time Choosen Must be Greater than Start Time");
                        SetSelectedTime({ ...SelectedTime });
                        setDayTimings({ ...DayTimings });
                        return false;
                    }
                } else if (day == "StartTime") {
                    if (SelectedTime?.[timingtype]?.[index]?.EndTimeshow <= e._d) {
                        toast.error("Time Choosen Must be Less than End Time");
                        SetSelectedTime({ ...SelectedTime });
                        setDayTimings({ ...DayTimings });
                        return false;
                    }
                }
                setvalue = {
                    ...SelectedTime,
                    ...{
                        [timingtype]: [
                            ...SelectedTime?.[timingtype]?.slice(0, index),
                            ...[
                                {
                                    ...SelectedTime?.[timingtype]?.[index],
                                    ...{
                                        [day]:
                                            MinuteHourFormat(e._d.getHours()) +
                                            ":" +
                                            MinuteHourFormat(e._d.getMinutes()),
                                        [day + "show"]: e._d,
                                    },
                                },
                            ],
                            ...SelectedTime?.[timingtype]?.slice(
                                index + 1,
                                SelectedTime?.[timingtype]?.length
                            ),
                        ],
                    },
                };
            }
            let instdaytiming = DayTimings;
            let Time = {
                StartTime: HourMinutetoTime(
                    setvalue?.[timingtype]?.[index]?.StartTime.split(":")[0],
                    setvalue?.[timingtype]?.[index]?.StartTime.split(":")[1]
                ),
                EndTime: HourMinutetoTime(
                    setvalue?.[timingtype]?.[index]?.EndTime.split(":")[0],
                    setvalue?.[timingtype]?.[index]?.EndTime.split(":")[1]
                ),
            };

            if ((type == "checkbox" && e?.target?.checked) || type == "time") {
                if (Object.keys(setvalue?.[timingtype]?.[index])?.length > 0) {
                    Object.keys(setvalue[timingtype][index]).map((val) => {
                        if (
                            val != "StartTime" &&
                            val != "StartTimeshow" &&
                            val != "EndTime" &&
                            val != "EndTimeshow"
                        ) {
                            if (DayTimings?.[val] && setvalue?.[timingtype]?.[index]?.[val]) {
                                let curdaytimeind = DayTimings?.[val]?.[
                                    timingtype
                                ]?.timing?.findIndex(
                                    (findindexval) =>
                                        findindexval?.StartTime ==
                                        SelectedTime?.[timingtype]?.[index]?.StartTime &&
                                        findindexval?.EndTime ==
                                        SelectedTime?.[timingtype]?.[index]?.EndTime
                                );
                                if (curdaytimeind != -1) {
                                    instdaytiming = {
                                        ...instdaytiming,
                                        ...{
                                            [val]: {
                                                ...instdaytiming?.[val],
                                                ...{
                                                    [timingtype]: {
                                                        ...instdaytiming?.[val]?.[timingtype],
                                                        ...{
                                                            Status: true,
                                                            timing: [
                                                                ...instdaytiming?.[val]?.[
                                                                    timingtype
                                                                ]?.timing?.slice(0, curdaytimeind),
                                                                ...[
                                                                    {
                                                                        ...instdaytiming[val]?.[timingtype]
                                                                            ?.timing?.[curdaytimeind],
                                                                        ...{
                                                                            StartTime:
                                                                                setvalue?.[timingtype]?.[index]
                                                                                    ?.StartTime,
                                                                            StartHour:
                                                                                setvalue?.[timingtype]?.[
                                                                                    index
                                                                                ]?.StartTime?.split(":")[0],
                                                                            StartMinute:
                                                                                setvalue?.[timingtype]?.[
                                                                                    index
                                                                                ]?.StartTime?.split(":")[1],
                                                                            EndTime:
                                                                                setvalue?.[timingtype]?.[index]
                                                                                    ?.EndTime,
                                                                            EndHour:
                                                                                setvalue?.[timingtype]?.[
                                                                                    index
                                                                                ]?.EndTime?.split(":")[0],
                                                                            EndMinute:
                                                                                setvalue?.[timingtype]?.[
                                                                                    index
                                                                                ]?.EndTime?.split(":")[1],
                                                                        },
                                                                    },
                                                                ],
                                                                ...instdaytiming[val]?.[
                                                                    timingtype
                                                                ]?.timing?.slice(
                                                                    curdaytimeind + 1,
                                                                    instdaytiming[val]?.[timingtype]?.timing
                                                                        ?.length
                                                                ),
                                                            ],
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    };
                                    Object.keys(instdaytiming[val]).map((insttimingtype) => {
                                        if (insttimingtype != "Timing" && insttimingtype != "day") {
                                            if (
                                                instdaytiming?.[val]?.[insttimingtype]?.Status ==
                                                false ||
                                                instdaytiming?.[val]?.[instdaytiming]?.timing?.length ==
                                                0
                                            ) {
                                                if (insttimingtype != "DeliveryTimeCharge") {
                                                    instdaytiming = {
                                                        ...instdaytiming,
                                                        ...{
                                                            [val]: {
                                                                ...instdaytiming[val],
                                                                ...{
                                                                    [insttimingtype]: {
                                                                        ...instdaytiming[val][insttimingtype],
                                                                        ...{
                                                                            Status: true,
                                                                            AfterPreparation: [],
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    };
                                                } else {
                                                    instdaytiming = {
                                                        ...instdaytiming,
                                                        ...{
                                                            [val]: {
                                                                ...instdaytiming[val],
                                                                ...{
                                                                    [insttimingtype]: {
                                                                        ...instdaytiming[val][insttimingtype],
                                                                        ...{
                                                                            StartHour: "",
                                                                            StartMinute: "",
                                                                            StartTime: "",
                                                                            EndHour: "",
                                                                            EndMinute: "",
                                                                            EndTime: "",
                                                                            Status: true,
                                                                            timing: [],
                                                                        },
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    };
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    instdaytiming = {
                                        ...instdaytiming,
                                        ...{
                                            [val]: {
                                                ...instdaytiming[val],
                                                ...{
                                                    [timingtype]: {
                                                        ...instdaytiming[val][timingtype],
                                                        ...{
                                                            Status: true,
                                                            timing: [
                                                                ...instdaytiming[val]?.[timingtype]?.timing,
                                                                ...[
                                                                    {
                                                                        StartTime:
                                                                            setvalue?.[timingtype]?.[index]
                                                                                ?.StartTime,
                                                                        StartHour:
                                                                            setvalue?.[timingtype]?.[
                                                                                index
                                                                            ]?.StartTime?.split(":")[0],
                                                                        StartMinute:
                                                                            setvalue?.[timingtype]?.[
                                                                                index
                                                                            ]?.StartTime?.split(":")[1],
                                                                        EndTime:
                                                                            setvalue?.[timingtype]?.[index]?.EndTime,
                                                                        EndHour:
                                                                            setvalue?.[timingtype]?.[
                                                                                index
                                                                            ]?.EndTime?.split(":")[0],
                                                                        EndMinute:
                                                                            setvalue?.[timingtype]?.[
                                                                                index
                                                                            ]?.EndTime?.split(":")[1],
                                                                    },
                                                                ],
                                                            ],
                                                        },
                                                    },
                                                },
                                            },
                                        },
                                    };
                                }
                            }
                        }
                    });
                }
            } else if (type == "checkbox" && !e?.target?.checked) {
                let timingindex = instdaytiming?.[day]?.[timingtype]?.timing?.findIndex(
                    (daytimeindex) =>
                        daytimeindex?.StartTime ==
                        SelectedTime?.[timingtype]?.[index]?.StartTime &&
                        daytimeindex?.EndTime ==
                        SelectedTime?.[timingtype]?.[index]?.EndTime
                );
                if (timingindex != -1) {
                    let newtiming = {
                        StartHour: "",
                        StartMinute: "",
                        StartTime: "",
                        StartTimeshow: "",
                        EndHour: "",
                        EndMinute: "",
                        EndTime: "",
                        EndTimeshow: "",
                    };
                    instdaytiming = {
                        ...instdaytiming,
                        ...{
                            [day]: {
                                ...instdaytiming[day],
                                ...{
                                    [timingtype]: {
                                        ...instdaytiming[day][timingtype],
                                        ...{
                                            timing: [
                                                ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                                                    0,
                                                    timingindex
                                                ),
                                                ...instdaytiming[day]?.[timingtype]?.timing?.slice(
                                                    timingindex + 1,
                                                    instdaytiming[day]?.[timingtype]?.timing?.length
                                                ),
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    };
                    if (timingtype == "Timing") {
                        Object.keys(instdaytiming).map((days) => {
                            if (
                                instdaytiming?.[days]?.Timing?.Status &&
                                instdaytiming?.[days]?.Timing?.timing?.length == 0
                            ) {
                                instdaytiming = {
                                    ...instdaytiming,
                                    ...{
                                        [days]: {
                                            ...instdaytiming[days],
                                            ...{
                                                [timingtype]: {
                                                    ...instdaytiming[days][timingtype],
                                                    ...{
                                                        StartHour: "",
                                                        StartMinute: "",
                                                        StartTime: "",
                                                        EndHour: "",
                                                        EndMinute: "",
                                                        EndTime: "",
                                                        Status: false,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                };
                                Object.keys(instdaytiming[days]).map((insttimingtype) => {
                                    if (insttimingtype != "Timing" && insttimingtype != "day") {
                                        if (
                                            instdaytiming?.[days]?.[insttimingtype]?.Status ||
                                            instdaytiming?.[days]?.[instdaytiming]?.timing?.length > 0
                                        ) {
                                            if (insttimingtype != "DeliveryTimeCharge") {
                                                instdaytiming = {
                                                    ...instdaytiming,
                                                    ...{
                                                        [days]: {
                                                            ...instdaytiming[days],
                                                            ...{
                                                                [insttimingtype]: {
                                                                    ...instdaytiming[days][insttimingtype],
                                                                    ...{
                                                                        Status: false,
                                                                        AfterPreparation: [],
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                };
                                            } else {
                                                instdaytiming = {
                                                    ...instdaytiming,
                                                    ...{
                                                        [days]: {
                                                            ...instdaytiming[days],
                                                            ...{
                                                                [insttimingtype]: {
                                                                    ...instdaytiming[days][insttimingtype],
                                                                    ...{
                                                                        StartHour: "",
                                                                        StartMinute: "",
                                                                        StartTime: "",
                                                                        EndHour: "",
                                                                        EndMinute: "",
                                                                        EndTime: "",
                                                                        Status: false,
                                                                        timing: [],
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    },
                                                };
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            }
            Object.keys(instdaytiming).map((days) => {
                if (instdaytiming[days][timingtype]?.Status) {
                    let maxtime = GetMaxendMinstart(instdaytiming, days, timingtype);
                    instdaytiming = {
                        ...instdaytiming,
                        ...{
                            [days]: {
                                ...instdaytiming[days],
                                ...{
                                    [timingtype]: {
                                        ...instdaytiming[days][timingtype],
                                        ...maxtime,
                                    },
                                },
                            },
                        },
                    };
                }
            });
            SetSelectedTime({ ...setvalue });
            setDayTimings({ ...instdaytiming });
        } catch (err) {
            console.log("OnChangeTime Error", err);
        }
    };

    const GetMaxendMinstart = (instdaytiming, days, timingtype) => {
        let minstart = "";
        let maxend = "";
        if (instdaytiming?.[days]?.[timingtype]?.timing?.length > 0) {
            instdaytiming[days][timingtype].timing.map((timings) => {
                if (
                    minstart == "" ||
                    HourMinutetoTime(timings?.StartHour, timings?.StartMinute) <
                    HourMinutetoTime(minstart.split(":")[0], minstart.split(":")[1])
                ) {
                    minstart = timings?.StartTime;
                }
                if (
                    maxend == "" ||
                    HourMinutetoTime(timings?.EndHour, timings?.EndMinute) <
                    HourMinutetoTime(maxend.split(":")[0], maxend.split(":")[1])
                ) {
                    maxend = timings?.EndTime;
                }
            });
        }

        return {
            StartHour: minstart ? minstart.split(":")[0] : "",
            StartMinute: minstart ? minstart.split(":")[1] : "",
            StartTime: minstart,
            EndHour: maxend ? maxend.split(":")[0] : "",
            EndMinute: maxend ? maxend.split(":")[1] : "",
            EndTime: maxend,
        };
    };

    const HourMinutetoTime = (hour, minute, from) => {
        // console.log('mapppconddconverttt',hour,minute,from,(isEmpty(hour) && isEmpty(minute) && hour != 0 && minute != 0),0 == Number(hour) && 0 == Number(minute))
        if (isEmpty(hour) && isEmpty(minute) && hour != 0 && minute != 0) {
            if (from == "end") {
                return new Date(new Date().setHours(0, 0));
            } else if (from == "start") {
                return new Date(new Date().setHours(0, 0, 0));
            }
            return new Date(new Date().setHours(23, 59, 59));
        }
        if (0 == Number(hour) && 0 == Number(minute)) {
            return new Date(new Date().setHours(0, 0, 0, 0));
        }
        return new Date(
            new Date().setHours(
                hour >= 24 ? 23 : hour,
                hour >= 24 ? 59 : minute,
                minute == 59 || hour >= 24 ? 59 : 0
            )
        );
    };

    const AddNewTiming = (type) => {
        SetChooseTimeArray([...ChooseTimeArray, ...[ChooseTimeArray.length + 1]]);
        SetSelectedTime({
            ...SelectedTime,
            ...{
                [type]: [
                    ...SelectedTime?.[type],
                    ...[
                        {
                            StartTime: "00:00",
                            StartTimeshow: HourMinutetoTime(0, 0),
                            EndTime: "24:00",
                            EndTimeshow: HourMinutetoTime(23, 59),
                        },
                    ],
                ],
            },
        });
    };

    const RemoveTime = (index, type) => {
        let insttimings = DayTimings;
        Object.keys(SelectedTime?.[type]?.[index]).map((val) => {
            if (
                val != "EndTime" &&
                val != "EndTimeshow" &&
                val != "StartTime" &&
                val != "StartTimeshow"
            ) {
                let maxtime = {
                    StartTime: "",
                    StartTimeshow: "",
                    StartHour: "",
                    StartMinute: "",
                    EndTime: "",
                    EndTimeshow: "",
                    EndHour: "",
                    EndMinute: "",
                };
                let findtimingindex = insttimings?.[val]?.[type]?.timing?.findIndex(
                    (findinst) =>
                        findinst?.StartTime == SelectedTime?.[type]?.[index]?.StartTime &&
                        findinst?.EndTime == SelectedTime?.[type]?.[index]?.EndTime
                );
                if (findtimingindex != -1) {
                    insttimings = {
                        ...insttimings,
                        ...{
                            [val]: {
                                ...insttimings?.[val],
                                ...{
                                    [type]: {
                                        ...insttimings?.[val]?.[type],
                                        ...{
                                            timing: [
                                                ...insttimings?.[val]?.[type]?.timing?.slice(
                                                    0,
                                                    findtimingindex
                                                ),
                                                ...insttimings?.[val]?.[type]?.timing?.slice(
                                                    findtimingindex + 1,
                                                    insttimings?.[val]?.[type]?.timing?.length
                                                ),
                                            ],
                                        },
                                    },
                                },
                            },
                        },
                    };
                }
            }
        });
        setDayTimings({ ...insttimings });
        SetChooseTimeArray([
            ...ChooseTimeArray.slice(0, index),
            ...ChooseTimeArray.slice(index + 1, ChooseTimeArray?.length),
        ]);
        SetSelectedTime({
            ...SelectedTime,
            ...{
                [type]: [
                    ...SelectedTime?.[type]?.slice(0, index),
                    ...SelectedTime?.[type]?.slice(
                        index + 1,
                        SelectedTime?.[type]?.length
                    ),
                ],
            },
        });
    };

    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>

                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>

                                <h5 className={`${styled.mtb}`}>Chat Activation</h5>


                                <div className="mt-4">
                                    <div className="row mx-auto">
                                        <div className="col-12">
                                            <div className="d-flex flex-column align-items-start ">
                                                <label className="custom_label mb-0 px-0">Duration</label>
                                                <div className="w-100">
                                                    <div className="mangra_overflow_align mangra_overflow_align_new">
                                                        <div className="avail_timing">
                                                            <div className="d-flex align-items-center manual_graph_timing">
                                                                <p>Day</p>
                                                                <p className="mid_frm_lft">Midnight</p>
                                                                <p className="four_frm_lft">4AM</p>
                                                                <p className="egt_frm_lft">8AM</p>
                                                                <p className="lnc_frm_lft">Lunch</p>
                                                                <p className="frpm_frm_lft">4PM</p>
                                                                <p className="egtpm_frm_lft">8PM</p>
                                                            </div>

                                                            {Object.keys(DayTimings)?.map((val) => {
                                                                return (
                                                                    <div className="manual_graph_single man_grp_sng_gap d-flex align-items-center">
                                                                        <p className="manualgra_yaxis_txt mb-0 me-3">
                                                                            {val.substring(0, 3)}
                                                                        </p>
                                                                        {HourMinuteDisplay?.Hours?.map((hour) => {
                                                                            return (
                                                                                <div className="single_square">
                                                                                    {HourMinuteDisplay?.Minutes?.map(
                                                                                        (minute) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className={
                                                                                                        DayTimings?.[val]?.Timing
                                                                                                            ?.timing?.length > 0
                                                                                                            ? DayTimings?.[
                                                                                                                val
                                                                                                            ]?.Timing?.timing?.some(
                                                                                                                (val) =>
                                                                                                                    HourMinutetoTime(
                                                                                                                        hour,
                                                                                                                        minute * 15
                                                                                                                    ) >=
                                                                                                                    HourMinutetoTime(
                                                                                                                        val?.StartHour,
                                                                                                                        val?.StartMinute,
                                                                                                                        "start"
                                                                                                                    ) &&
                                                                                                                    HourMinutetoTime(
                                                                                                                        hour,
                                                                                                                        minute * 15
                                                                                                                    ) <=
                                                                                                                    HourMinutetoTime(
                                                                                                                        val?.EndHour,
                                                                                                                        val?.EndMinute,
                                                                                                                        "end"
                                                                                                                    ) &&
                                                                                                                    (minute * 15 !== 60 ||
                                                                                                                        (minute * 15 == 60 &&
                                                                                                                            hour !=
                                                                                                                            Number(
                                                                                                                                val?.StartHour
                                                                                                                            ) -
                                                                                                                            1))
                                                                                                            )
                                                                                                                ? "timing_div"
                                                                                                                : "test111"
                                                                                                            : "test111"
                                                                                                    }
                                                                                                ></div>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className="mt-5">
                                                            {selectCheck && (
                                                                <>
                                                                    <div className="cmn_ttl_aligns d-flex align-items-center">
                                                                        <div className="title_percent_ttl me-5 w-50">
                                                                            <p className="checkbox_title  text_black_md mb-3">
                                                                                Select day(s):
                                                                            </p>
                                                                            <div className="daysslct_ttl  d-flex align-items-center gap-4">
                                                                                {Object.keys(DayTimings)?.map((val) => {
                                                                                    return (
                                                                                        <div className="show_days_div">
                                                                                            <p className="text_green_md mb-2">
                                                                                                {val?.substring(0, 1)}
                                                                                            </p>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>{" "}
                                                                        </div>
                                                                        <div className="title_percent_ttl  w-50">
                                                                            <p className="checkbox_title  text_black_md mb-3">
                                                                                Select times:
                                                                            </p>
                                                                            <div className="from_to_timePick times">
                                                                                <div className="date_min_widt">
                                                                                    <p className="text_green_md mb-2">From</p>
                                                                                </div>
                                                                                <div className="date_min_widt">
                                                                                    <p className="text_green_md mb-2">To</p>
                                                                                </div>
                                                                                <div className="date_min_widt">
                                                                                    <p className="text_green_md mb-2">
                                                                                        Action
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {SelectedTime?.Timing?.length > 0 &&
                                                                        SelectedTime?.Timing?.map((arrind, ind) => {
                                                                            return (
                                                                                <div className="cmn_ttl_aligns d-flex align-items-center">
                                                                                    <div className="title_percent_ttl me-5 w-50">
                                                                                        <div className="daysslct_ttl  d-flex align-items-center gap-4">
                                                                                            {Object.keys(DayTimings)?.map(
                                                                                                (val) => {
                                                                                                    return (
                                                                                                        <div className="show_days_div">
                                                                                                            <Form.Check type="checkbox">
                                                                                                                <Form.Check.Input
                                                                                                                    type="checkbox"
                                                                                                                    defaultChecked={
                                                                                                                        SelectedTime?.Timing?.[
                                                                                                                        ind
                                                                                                                        ]?.[val]
                                                                                                                    }
                                                                                                                    isValid
                                                                                                                    onClick={(e) => {
                                                                                                                        OnChangeTime(
                                                                                                                            e,
                                                                                                                            ind,
                                                                                                                            val,
                                                                                                                            "checkbox",
                                                                                                                            "Timing"
                                                                                                                        );
                                                                                                                    }}
                                                                                                                />
                                                                                                            </Form.Check>
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </div>{" "}
                                                                                    </div>
                                                                                    <div className="title_percent_ttl w-50">
                                                                                        <div className="from_to_timePick">
                                                                                            <div className="date_min_widt">
                                                                                                <div className="customDatePicker">
                                                                                                    <Datetime
                                                                                                        viewMode={"time"}
                                                                                                        value={
                                                                                                            SelectedTime?.Timing?.[ind]
                                                                                                                ?.StartTimeshow
                                                                                                        }
                                                                                                        // onViewModeChange={'time'}
                                                                                                        dateFormat={false}
                                                                                                        onChange={(e) => {
                                                                                                            OnChangeTime(
                                                                                                                e,
                                                                                                                ind,
                                                                                                                "StartTime",
                                                                                                                "time",
                                                                                                                "Timing"
                                                                                                            );
                                                                                                        }}
                                                                                                    // disabled={DisableCondition()}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="date_min_widt">
                                                                                                <div className="customDatePicker">
                                                                                                    <Datetime
                                                                                                        viewMode={"time"}
                                                                                                        placeholder="start"
                                                                                                        value={
                                                                                                            SelectedTime?.Timing?.[ind]
                                                                                                                ?.EndTimeshow
                                                                                                        }
                                                                                                        // onViewModeChange={'time'}
                                                                                                        dateFormat={false}
                                                                                                        onChange={(e) => {
                                                                                                            OnChangeTime(
                                                                                                                e,
                                                                                                                ind,
                                                                                                                "EndTime",
                                                                                                                "time",
                                                                                                                "Timing"
                                                                                                            );
                                                                                                        }}
                                                                                                    // disabled={DisableCondition()}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                            {ind > 0 ? (
                                                                                                <button className="date_min_widt bg-transparent outline-0 border-0 text-start">
                                                                                                    <FaTrash
                                                                                                        fontSize={20}
                                                                                                        fill="#2d699b"
                                                                                                        onClick={() =>
                                                                                                            RemoveTime(ind, "Timing")
                                                                                                        }
                                                                                                    />
                                                                                                </button>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}

                                                                    <div className="triple_copybtns d-flex align-items-center gap-4 mt-2">
                                                                        <button
                                                                            className="allbtn"
                                                                            onClick={() => AddNewTiming("Timing")}
                                                                        >
                                                                            Add Time
                                                                        </button>{" "}
                                                                    </div>
                                                                </>
                                                            )}
                                                            {Errors?.Timing && (
                                                                <span className="error_msg">{Errors?.Timing}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <form>
                                    <div className={`row ${styled.formsec}`}>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Content</label>
                                            {
                                                selectCheck
                                                ?
                                                <SunEditor
                                                ref={editorRef}
                                                setOptions={editorOptions}
                                                // onImageUploadError={onImageUploadError}
                                                onChange={onEditorChange}
                                                defaultValue={answer}
                                            />
                                                :
                                                <></>
                                            }
                                            
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Enter Initial Text</label>
                                            <input type="text" className={`formcontrol`} placeholder='Enter Initial Text' id="initialtext" value={formData?.initialtext} onChange={(e) => OnChange(e)}/>
                                            <div className={`${styled.mtb}`}>
                                                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mes}`} onClick={()=>navigate(-1)} >Cancel</button>
                                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={onSubmit}>Save</button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div dangerouslySetInnerHTML={{ __html: value }} ></div> */}
                                </form>

                            </div>
                        </div>
                    </div>


                </div>

            </Layout>

        </div>
    )
}

export default Chatsettings