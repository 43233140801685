
import CryptoJS, { AES, enc } from "crypto-js";

// import {encodekey} from '../../views/config/config' ;

// config

import config from './config'


// let config ={}
// config.ENCODEKEY = "answerkey"
export const Encryptdata = (data) => {
  if (typeof (data) == 'string') {
    return CryptoJS.AES.encrypt(data, config.SECRET_KEY).toString()
  }
  else {
    return CryptoJS.AES.encrypt(JSON.stringify(data), config.SECRET_KEY).toString()
  }
}
  
export const Decryptdata = (data) => {
  try{
  var bytes = CryptoJS.AES.decrypt(data, config.SECRET_KEY);
  
  var decryptedData;
  try {
    decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  catch (err) {
    
    try {
      decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      

    }
    catch (dcpterr) {
      
      return undefined;
    }
  }
  return decryptedData;
  }
  catch(error){
    
    return undefined;
  }
}

// Common Formdata function
export const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map(data => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))
              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return [formdata]
}
  