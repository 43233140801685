import React, { Component, Suspense, lazy, useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, Routes, useNavigate, Navigate } from 'react-router-dom';


import Spinner from './shared/Spinner.js';


// public private routes

import AdminRoute from './authRoutes/adminRoutes.js';

import commonRoutes from './authRoutes/commonRoutes.js';
import { AuthenticateUser } from './axioscalls/useaxios.js';
import { Account_Connect } from './redux/action.js';
import Login from './pages/loginpages/login.jsx';
import Userdetail from './pages/user/userdetail.jsx';


const notloginroutes = []
commonRoutes.map((val) => { if (val.module == 'login') notloginroutes.push(val?.path) });

export default function AppRoutes(props) {

	const Wallet_Details = useSelector((state) => state.wallet_detail)
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate()

	var token = localStorage.adminlogin;
	const isAuthenticated = token ? true : false

	const validateAuth = (comp) => {
		return isAuthenticated ? comp : <Navigate to={"/"} replace />;
	  };


	const adminRoutes = []
	commonRoutes.map(({ path, component, name, module }, key) => {
		if ((module == 'login' && Wallet_Details?.loggedin == 'false') || (Wallet_Details?.loggedin == 'unknown') || (Wallet_Details?.loggedin != 'true' && module == 'login') || (module != 'login' && Wallet_Details?.loggedin == 'true' && (Wallet_Details?.payload?.Role == "superadmin" || Wallet_Details?.payload?.Permission?.[module] || path == '/users'))) {
			adminRoutes.push(
				<Route path={path} element={component} key={key} name={name} />
			)
		}
	})


	useEffect(() => {
		CheckUser();
	}, [Wallet_Details?.loggedin])

	const NotLoginReDirect = () => {
		if (!notloginroutes.includes(location.pathname)) {
			if (Wallet_Details?.loggedin != 'false') {
				dispatch({
					type: Account_Connect,
					Account_Detail: {
						loggedin: 'false',
						token: '',
					}
				})
			}
			navigate('/');
		}
	}

	const CheckUser = async () => {
		if (Wallet_Details?.loggedin == 'false') {
			NotLoginReDirect()
		}
		else {
			const usertoken = ("; " + document.cookie)
				.split(`; admintoken=`)
				.pop()
				.split(";")[0];
			if (usertoken) {
				let resp = await AuthenticateUser({ token: usertoken })
				console.log('AuthenticateUserresp', resp)
				if (resp.status) {
					dispatch({
						type: Account_Connect,
						Account_Detail: {
							loggedin: 'true',
							token: 'skip',
							payload: resp?.data,
						}
					})
				}
				else {
					localStorage.removeItem('token');
					localStorage.removeItem('walletConnectType');
					localStorage.setItem("adminlogin", "no");
					document.cookie = "admintoken" + "= ; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
					NotLoginReDirect()
				}

			}
			else {
				localStorage.removeItem('token');
				localStorage.removeItem('walletConnectType');
				localStorage.setItem("adminlogin", "no");
				document.cookie = "admintoken" + "= ; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
				NotLoginReDirect()
			}
		}
	}
	console.log('adminRoutes---->', adminRoutes);
	return (
		<>
			<Routes>
			{adminRoutes}
				{/* <Route path="/" element={<Login />} />
				<Route path="*" element={<Userdetail />} /> */}
			</Routes>
			{/* <Suspense fallback={<Spinner />}> */}
			{/* <Routes> */}
			{/* <AdminRoute
						isAuth={isAuthenticated}> */}
			
			{/* </AdminRoute> */}
			{/* </Routes> */}
			{/* </Suspense> */}
		</>

	);

}


