import React, { useState, Fragment, useEffect } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
// import ReactDatatable from "react-data-table-component";
import DataTable from 'react-data-table-component';
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useSelector } from 'react-redux';
import { getInstances } from '../../axioscalls/chat';


function Chatlist() {


 

  var location = useLocation();
  const Wallet_Details = useSelector((state) => state.wallet_detail)

  const { pathname, state } = location;
  const path = pathname.split("/")[1]


  useEffect(() => {
      getallinstances();
  }, [])

  const [userList, setUserList] = useState([])

    const getallinstances = async ()=>{
          let resp = await getInstances()
          console.log('getInstancesresp',resp);
          if(resp?.success == "success"){
              setUserList(resp?.data)
          }
    }


  const columns = [
      {
          key: "",
          name: "SNO",
          className: "NFT",
          align: "left",
          sortable: true,
          cell: (record, index) =>
              <div>{index + 1}
              </div>

      },
      {
          key: "EmailId",
          name: "Email",
          className: "NFT",
          align: "left",
          cell: rec =>
              <div className='d-flex justify-content-between'><div>{rec?.EmailId}</div>
              {/* <div className=''>{rec.unseenCount}</div> */}
              {rec.unseenCount > 0 &&
              <span className='unread border border-success ms-1'></span>}
              </div>
      },
      
      {
          key: "Status",
          name: "Status",
          className: "NFT",
          align: "left",
          cell: rec =>
              <div><button className=' allbtn'>{rec?.Status}</button></div>
      },

      {
          name: "Action",
          cell: record =>
              <div><Link to={{ pathname: `/chatdetail` }} state={record} ><button className=' allbtn'>view</button></Link></div>

      },

  ]

  

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>
           

              <div className={`${styled.tablesec}`}>
                <h5 className={`${styled.cardtitle}`}>CHAT LIST</h5>

               <Link to="/chatsettings"><button type='button' className={`themebtn ${styled.addbtn} ${styled.mtb}`}>Chat Settings</button></Link>

               {/* <div className={`row ${styled.formsec} ${styled.mtb}`}>
                  <div className={`col-xl-6 col-xxl-4 ms-auto`}>
                    <input
                      type="text"
                      className={'formcontrol'}
                      placeholder="Search"
                      value={filterText}
                      onChange={handleSearch}
                      style={{ padding: '0.8vh', width: '100%' }}
                    />
                  </div>
                </div> */}

               
               <DataTable
                  className='react-datatables'
                  columns={columns}
                  data={userList}
                  pagination // Enable pagination
                  // paginationPerPage={2} // Rows per page
                  paginationRowsPerPageOptions={[5, 10, 15, 20]} // Page options
                />

                {/* <ReactDatatable
                  className='react-datatables'
                  config={config}
                  data={records}
                  columns={columns}
                // customStyles={customStyles}
                // extraButtons={extraButtons}
                /> */}
              </div>
            </div>


          </div>
        </div>

      </Layout>
    
    </div>
  )
}

export default Chatlist