// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_layoutsec__UOYdX .layout_sidebarsec__5TyI-{
    /* width: 16vw; */
    width: 15vw;
}
.layout_layoutsec__UOYdX .layout_contentsec__WUus4{
    /* width: 84vw; */
    width: 85vw;
    padding: 3%;
    overflow: auto;
    height: 85vh;
}


@media screen and  (max-width: 1199px) {
    .layout_layoutsec__UOYdX .layout_sidebarsec__5TyI-{
        width: 0vw;
    }
    .layout_layoutsec__UOYdX .layout_contentsec__WUus4{
        width: 100vw;
    } 
}`, "",{"version":3,"sources":["webpack://./src/assets/css/layout.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,WAAW;IACX,cAAc;IACd,YAAY;AAChB;;;AAGA;IACI;QACI,UAAU;IACd;IACA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".layoutsec .sidebarsec{\n    /* width: 16vw; */\n    width: 15vw;\n}\n.layoutsec .contentsec{\n    /* width: 84vw; */\n    width: 85vw;\n    padding: 3%;\n    overflow: auto;\n    height: 85vh;\n}\n\n\n@media screen and  (max-width: 1199px) {\n    .layoutsec .sidebarsec{\n        width: 0vw;\n    }\n    .layoutsec .contentsec{\n        width: 100vw;\n    } \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutsec": `layout_layoutsec__UOYdX`,
	"sidebarsec": `layout_sidebarsec__5TyI-`,
	"contentsec": `layout_contentsec__WUus4`
};
export default ___CSS_LOADER_EXPORT___;
