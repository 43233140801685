import React, { useEffect, useRef, useState } from "react";
// import styled from '../assets/css/settings.module.css';
import styled from "../assets/css/profile.module.css";
import { FaPen } from "react-icons/fa";
import Images from "../assets/images/images";
import Layout from "../components/layout";
import { useDispatch, useSelector } from "react-redux";
import { GetCookie, ImgEmpty, ImgUrl, isEmpty } from "../lib/common";
import { toast } from "react-toastify";
import { AuthenticateUser, EditProfile } from "../axioscalls/useaxios";
import config from "../lib/config";
import { Form } from "react-bootstrap";
import { Account_Connect } from "../redux/action";

// import Changepwdmodal from '../modals/changepwdmodal';

function Profilesettings() {
  const dispatch = useDispatch();

  const [admindata, SetAdminData] = useState({});
  const Wallet_Details = useSelector((state) => state.wallet_detail);
  const [ValidateError, SetValidateError] = useState({});

  const OnChange = (e) => {
    let id = e?.target?.id ? e?.target?.id : e?.id;
    let value = e?.target?.value ? e?.target?.value : e?.value;
    let file = e?.target?.files?.[0];
    if (file) {
      if (!file.type?.includes("image")) {
        toast.error("Upload Image Only.");
        return SetValidateError({ Profile: "Upload Image Only." });
      }
    }
    SetAdminData({
      ...admindata,
      ...{
        [id]: file ? file : value,
      },
    });
  };

  const Validate = () => {
    let errorval = {};
    if (isEmpty(admindata?.UserName)) {
      errorval.UserName = "Enter User Name";
    }
    if (isEmpty(admindata?.EmailId)) {
      errorval.EmailId = "Enter Email-Id";
    } else if (!config.EMAIL.test(admindata?.EmailId)) {
      errorval.EmailId = "Enter Valid Email-Id";
    }
    if (!isEmpty(admindata?.Password)) {
      if (!config.PASSWORD.test(admindata?.Password)) {
        errorval.Password = "Password must contain alphanumeric";
      }
    }
    if (ImgEmpty(admindata?.Profile)) {
      errorval.Profile = "Choose Profile Image";
    }
    return errorval;
  };

  const Submit = async () => {
    let toastid = toast.loading("Updating...");
    SetValidateError({});
    let error = Validate();
    if (isEmpty(error)) {
      let resp = await EditProfile(admindata);
      if (resp?.status) {
        toast.update(toastid, {
          render: resp?.msg,
          isLoading: false,
          autoClose: 1000,
          type: "success",
        });
        resp = await AuthenticateUser({ token: GetCookie() });
        console.log("AuthenticateUser_resp", resp);
        if (resp.status) {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              loggedin: "true",
              token: "skip",
              payload: resp?.data,
            },
          });
        }
      } else {
        toast.update(toastid, {
          render: resp?.msg,
          isLoading: false,
          autoClose: 1000,
          type: "error",
        });
      }
    } else {
      toast.update(toastid, {
        render: "Fix all validations",
        isLoading: false,
        autoClose: 1000,
        type: "error",
      });
      // toast.error("Fix all validations")
      SetValidateError(error);
    }
  };

  useEffect(() => {
    if (Wallet_Details?.payload) {
      SetAdminData(Wallet_Details?.payload);
    }
  }, [Wallet_Details?.payload]);
  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`${styled.profilesettings}`}>
            <div className={`row mx-0`}>
              <div
                className={`col-12 grid-margin stretch-card ${styled.profile_details}`}
              >
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mt-3">Profile</h4>
                    <form className="forms-sample">
                      <Form.Group className="mb-2">
                        <label
                          htmlFor="exampleInputName1"
                          className="formlabel mb-2"
                        >
                          Image
                        </label>
                        <div className={`${styled.uploadImg}`}>
                          {/* <img src={ProfileImg} className="profileImg" /> */}
                          <img
                            src={
                              ImgEmpty(admindata?.Profile)
                                ? Images.dummypf
                                : typeof admindata?.Profile == "string"
                                ? `${config.IMG_URL}/admin/${admindata?._id}/${admindata?.Profile}`
                                : typeof admindata?.Profile == "object"
                                ? ImgUrl(admindata?.Profile)
                                : Images.dummypf
                            }
                            className={`${styled.profileImg} img-fluid`}
                          />
                          <div className={`${styled.upload_btn_wrapper}`}>
                            <button className={`${styled.btn}`}>
                              Upload a file
                            </button>
                            <input
                              type="file"
                              name="myfile"
                              id="Profile"
                              onChange={(e) => OnChange(e)}
                            />
                          </div>
                        </div>
                        {ValidateError?.Profile && (
                          <span className="error_msg">
                            {ValidateError?.Profile}
                          </span>
                        )}
                      </Form.Group>
                      <div className="mb-2">
                        <label
                          htmlFor="exampleInputName1"
                          className="formlabel mb-2"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="formcontrol"
                          id="UserName"
                          onChange={(e) => OnChange(e)}
                          value={admindata?.UserName}
                        />
                        {ValidateError?.UserName && (
                          <span className="error_msg">
                            {ValidateError?.UserName}
                          </span>
                        )}
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor="exampleInputName2"
                          className="formlabel mb-2"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          className="formcontrol"
                          id="EmailId"
                          onChange={(e) => OnChange(e)}
                          value={admindata?.EmailId}
                        />
                        {ValidateError?.EmailId && (
                          <span className="error_msg">
                            {ValidateError?.EmailId}
                          </span>
                        )}
                      </div>
                      <div>
                        <label
                          htmlFor="exampleInputName2"
                          className="formlabel mb-2"
                        >
                          Passsword: (The password entered will be changed as
                          new password)
                        </label>
                        <input
                          type="text"
                          className="formcontrol"
                          id="Password"
                          onChange={(e) => OnChange(e)}
                          value={admindata?.Password}
                        />
                        {ValidateError?.Password && (
                          <span className="error_msg">
                            {ValidateError?.Password}
                          </span>
                        )}
                      </div>
                    </form>
                    <button
                      className="mt-2 allbtn"
                      type="button"
                      onClick={() => Submit()}
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      {/* {modalopen && <Changepwdmodal show={modalopen} handleClose={() => setModalopen(false)}/>} */}
    </div>
  );
}

export default Profilesettings;
