import React, { useState, Fragment, useEffect } from "react";
import Layout from "../../components/layout";
import { Form, Link, useLocation } from "react-router-dom";
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from "../../assets/css/user.module.css";
import { useNavigate } from "react-router-dom";
import dummyimg from "../../assets/images/dummypf.png";
import {
  KYCAction,
  KYCFieldSettingAction,
  UserdetailGet,
} from "../../axioscalls/useaxios";
import { toast } from "react-toastify";
import { FirstCaps, isEmpty } from "../../lib/common";
import config from "../../lib/config";
import PDFIMAGE from '../../assets/images/pdf.png'

function Kycdetail() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { detail }=props;
  const [userdata, Setuserdata] = useState(location.state);
  const [detail, Setdetails] = useState(location.state);
  const [KYCDetail, SetKYCDetail] = useState({});
  const [Error, SetError] = useState({});
  const [InputFields, SetInputFields] = useState({});

  // const detail = history.location.state
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    const res = await UserdetailGet({ id: userdata?._id });
    console.log("UserdetailGet_resp", res);
    if (res?.status) {
      let inputfields = await KYCFieldSettingAction({ action: "get" });
      SetInputFields(inputfields?.data);
      Setdetails(res.data);
      SetKYCDetail(res?.data?.kycdata);
    } else {
      Setdetails({});
      toast.error(res?.msg);
    }
  };

  const KYCUpdate = async (status, type) => {
    if (status == "rejected") {
      if (isEmpty(KYCDetail?.[type + "Reject"])) {
        toast.error("Enter Reason For Rejection");
        return SetError({ [type + "Reject"]: "Enter Reason For Rejection" });
      }
    }
    let Resp = await KYCAction({
      status: status,
      type: type,
      id: KYCDetail?._id,
      ...(status == "rejected"
        ? { [type + "Reject"]: KYCDetail?.[type + "Reject"] }
        : {}),
    });
    console.log("KYCAction_resp", Resp);
    if (Resp?.status) {
      toast.success(FirstCaps(status) + " successfully", 1000);
    } else {
      toast.error(Resp?.msg ? Resp?.msg : "Try-Again", 1000);
    }
    fetch();
  };

  const OnChange = (e) => {
    SetError({});
    let id = e?.target?.id;
    let value = e?.target?.value;
    SetKYCDetail({ ...KYCDetail, ...{ [id]: value } });
  };

  return (
    <div>
      <Layout>
        <div className={`${styled.innercontents}`}>
          <div className={`row mx-0`}>
            <div className={`col-12`}>
              <div className={`${styled.tablesec}`}>
                <button
                  type="button"
                  className={`themebtn ${styled.addbtn}`}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                <h5 className={`${styled.cardtitle}`}>KYC DETAILS</h5>
                <form>
                  <div className={`row ${styled.formsec}`}>
                    <div className={`col-12 ${styled.mbspace}`}>
                      <label for="exampleInputEmail1" className={`formlabel`}>
                        Registered Email-Id{" "}
                      </label>
                      <input
                        type="email"
                        className={`formcontrol`}
                        placeholder="para@gmail.com"
                        disabled
                        value={detail?.EmailId}
                      />
                    </div>
                  </div>
                </form>

                {(KYCDetail?.Verification?.PersonalDetails != "" &&
                  !isEmpty(KYCDetail?.Verification?.PersonalDetails)) ||
                (KYCDetail?.Verification?.PhysicalAddress != "" &&
                  !isEmpty(KYCDetail?.Verification?.PhysicalAddress)) ||
                (KYCDetail?.Verification?.BioMetric != "" &&
                  !isEmpty(KYCDetail?.Verification?.PersonalDetails)) ? (
                  <>
                    {KYCDetail?.Verification?.PersonalDetails != "" ||
                    KYCDetail?.Verification?.PhysicalAddress != "" ||
                    KYCDetail?.Verification?.BioMetric != "" ? (
                      <form>
                        {KYCDetail?.Verification?.PersonalDetails != "" && (
                          <>
                            <h5 className={`${styled.cardtitle}`}>
                              Personal Details
                            </h5>

                            {InputFields?.PersonalDetailsFields?.length > 0 ? (
                              InputFields?.PersonalDetailsFields.map((val) => {
                                return (
                                  <>
                                    {val.type == "file" ? (
                                      <>
                                        {/* <label>{val?.FieldName}: </label> */}
                                        <label
                                          for="exampleInputEmail1"
                                          className={`formlabel`}
                                        >
                                          {val?.FieldName}:{" "}
                                        </label>
                                        {KYCDetail?.BioMetric?.[val?.id] ? (
                                          <img
                                            src={
                                              config.IMG_URL +
                                              "/kyc/" +
                                              detail?._id +
                                              "/" +
                                              KYCDetail?.PhysicalAddress?.[
                                                val?.id
                                              ]
                                            }
                                          />
                                        ) : (
                                          <p>No Document Uploaded</p>
                                        )}
                                      </>
                                    ) : (
                                      <div className="mb-2">
                                        {/* <h4 className="card-title mt-3">{val?.FieldName}</h4> */}
                                        <label
                                          for="exampleInputEmail1"
                                          className={`formlabel`}
                                        >
                                          {val?.FieldName}{" "}
                                        </label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          className={`formcontrol`}
                                          placeholder={val?.FieldName}
                                          value={
                                            KYCDetail?.PersonalDetails?.[
                                              val?.id
                                            ] || ""
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}

                            <div>
                              <label className={`formlabel`}>
                                Reject Reason (Required if you are going to
                                Reject Personal Detail){" "}
                              </label>
                              <input
                                type="text"
                                className={`formcontrol`}
                                id="PersonalDetailsReject"
                                placeholder="Enter Reject Reason "
                                value={KYCDetail?.PersonalDetailsReject}
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.PersonalDetailsReject && (
                                <span className="error_msg">
                                  {Error?.PersonalDetailsReject}
                                </span>
                              )}
                            </div>

                            {userdata?.from == "view" ? (
                              <></>
                            ) : (
                              <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
                                {KYCDetail?.Verification?.PersonalDetails ==
                                "approved" ? (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    disabled={true}
                                  >
                                    Approved
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("approved", "PersonalDetails")
                                    }
                                  >
                                    Approve
                                  </button>
                                )}
                                {KYCDetail?.Verification?.PersonalDetails ==
                                "rejected" ? (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    disabled={true}
                                  >
                                    Rejected
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("rejected", "PersonalDetails")
                                    }
                                  >
                                    Reject
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}

                        {KYCDetail?.Verification?.PhysicalAddress != "" && (
                          <>
                            <h5 className={`${styled.cardtitle}`}>
                              Physical Address
                            </h5>

                            {InputFields?.PhysicalAddressFields?.length > 0 ? (
                              InputFields?.PhysicalAddressFields.map((val) => {
                                return (
                                  <>
                                    {val.type == "file" ? (
                                      <>
                                        <div className="mb-2">
                                          {/* <label>{val?.FieldName}: </label> */}
                                          <label
                                            for="exampleInputEmail1"
                                            className={`formlabel`}
                                          >
                                            {val?.FieldName}:{" "}
                                          </label>
                                          {KYCDetail?.BioMetric?.[val?.id] ? (
                                            <img
                                              src={
                                                config.IMG_URL +
                                                "/kyc/" +
                                                detail?._id +
                                                "/" +
                                                KYCDetail?.PhysicalAddress?.[
                                                  val?.id
                                                ]
                                              }
                                            />
                                          ) : (
                                            <p>No Document Uploaded</p>
                                          )}
                                        </div>
                                      </>
                                    ) : (
                                      <div className="mb-2">
                                        {/* <h4 className="card-title mt-3">{val?.FieldName}</h4> */}
                                        <label
                                          for="exampleInputEmail1"
                                          className={`formlabel`}
                                        >
                                          {val?.FieldName}{" "}
                                        </label>
                                        <input
                                          type="text"
                                          disabled={true}
                                          className={`formcontrol`}
                                          placeholder={val?.FieldName}
                                          value={
                                            KYCDetail?.PhysicalAddress?.[
                                              val?.id
                                            ]
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <></>
                            )}
                            <div>
                              <label className={`formlabel`}>
                                Reject Reason (Required if you are going to
                                Reject Physical Address){" "}
                              </label>
                              <input
                                type="text"
                                className={`formcontrol`}
                                id="PhysicalAddressReject"
                                placeholder="Enter Reject Reason "
                                value={KYCDetail?.PhysicalAddressReject}
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.PhysicalAddressReject && (
                                <span className="error_msg">
                                  {Error?.PhysicalAddressReject}
                                </span>
                              )}
                            </div>
                            {userdata?.from == "view" ? (
                              <></>
                            ) : (
                              <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
                                {KYCDetail?.Verification?.PhysicalAddress ==
                                "approved" ? (
                                  <button
                                    className=" allbtn mx-2"
                                    type="button"
                                    disabled={true}
                                  >
                                    Approved
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn mx-2"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("approved", "PhysicalAddress")
                                    }
                                  >
                                    Approve
                                  </button>
                                )}
                                {KYCDetail?.Verification?.PhysicalAddress ==
                                "rejected" ? (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    disabled={true}
                                  >
                                    Rejected
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("rejected", "PhysicalAddress")
                                    }
                                  >
                                    Reject
                                  </button>
                                )}
                              </div>
                            )}
                            {/* <button className=' allbtn' type='button' onClick={()=> KYCUpdate('rejected',"PhysicalAddress")} >Reject</button> */}
                          </>
                        )}

                        {KYCDetail?.Verification?.BioMetric != "" && (
                          <>
                            <h5 className="card-title mt-3 mb-2">Bio-Metric</h5>
                            <div className={`${styled.kyc_proof} mb-2`}>
                              {InputFields?.BioMetricFields?.length > 0 ? (
                                InputFields?.BioMetricFields.map((val) => {
                                  return (
                                    <>
                                      {val.type == "file" ||
                                      val.type == "Webcam" ? (
                                        <>
                                          <div className="mb-2">
                                            <div>
                                              <label
                                                for="exampleInputEmail1"
                                                className={`formlabel d-block`}
                                              >
                                                {val?.FieldName}:{" "}
                                              </label>

                                              {KYCDetail?.BioMetric?.[
                                                val?.id
                                              ] &&
                                              KYCDetail?.BioMetric?.[
                                                val?.id
                                              ].includes("webp") ? (
                                                <img
                                                  src={
                                                    config.IMG_URL +
                                                    "/kyc/" +
                                                    detail?._id +
                                                    "/" +
                                                    KYCDetail?.BioMetric?.[
                                                      val?.id
                                                    ]
                                                  }
                                                  className={`${styled.kyc_proof_img} img-fluid`}
                                                />
                                              ) : KYCDetail?.BioMetric?.[
                                                  val?.id
                                                ].includes("pdf") ? (
                                                <Link
                                                  to={
                                                    config.IMG_URL +
                                                    "/kyc/" +
                                                    detail?._id +
                                                    "/" +
                                                    KYCDetail?.BioMetric?.[
                                                      val?.id
                                                    ]
                                                  }
                                                  target="_blank"
                                                >
                                                  <img
                                                  src={
                                                    PDFIMAGE
                                                  }
                                                  className={`${styled.kyc_proof_img} img-fluid`}
                                                />
                                                </Link>
                                              ) : (
                                                <p className="mb-0">
                                                  No Document Uploaded
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <div>
                                          <label
                                            for="exampleInputEmail1"
                                            className={`formlabel`}
                                          >
                                            {val?.FieldName}{" "}
                                          </label>
                                          <input
                                            type="text"
                                            disabled={true}
                                            className={`formcontrol`}
                                            id={val?.id}
                                            placeholder={val?.FieldName}
                                            value={
                                              KYCDetail?.BioMetric?.[val?.id]
                                            }
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>

                            <div>
                              <label className={`formlabel`}>
                                Reject Reason (Required if you are going to
                                Reject Biometric){" "}
                              </label>
                              <input
                                type="text"
                                className={`formcontrol`}
                                id="BioMetricReject"
                                placeholder="Enter Rejet Reason"
                                value={KYCDetail?.BioMetricReject || ""}
                                onChange={(e) => OnChange(e)}
                              />
                              {Error?.BioMetricReject && (
                                <span className="error_msg">
                                  {Error?.BioMetricReject}
                                </span>
                              )}
                            </div>
                            {userdata?.from == "view" ? (
                              <></>
                            ) : (
                              <div className="mt-3 d-flex align-items-center justify-content-end gap-2">
                                {KYCDetail?.Verification?.BioMetric ==
                                "approved" ? (
                                  <button
                                    className=" allbtn mx-2"
                                    type="button"
                                    disabled={true}
                                  >
                                    Approved
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn mx-2"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("approved", "BioMetric")
                                    }
                                  >
                                    Approve
                                  </button>
                                )}
                                {KYCDetail?.Verification?.BioMetric ==
                                "rejected" ? (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    disabled={true}
                                  >
                                    Rejected
                                  </button>
                                ) : (
                                  <button
                                    className=" allbtn"
                                    type="button"
                                    onClick={() =>
                                      KYCUpdate("rejected", "BioMetric")
                                    }
                                  >
                                    Reject
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </form>
                    ) : (
                      <form className="forms-sample">
                        <h4>KYC Not Updated</h4>
                      </form>
                    )}
                  </>
                ) : (
                  <div className="mt-2 d-flex align-items-center justify-content-center">
                    <h6 className="mb-0">KYC Details Not Updated</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default Kycdetail;
