export const editorOptions = {
    height: 200,
    buttonList: [
        ["undo", "redo"],
        ["font", "fontSize"],
        // ['paragraphStyle', 'blockquote'],
        [
          "bold",
          "underline",
          "italic",
          "strike",
          "subscript",
          "superscript"
        ],
        ["fontColor", "hiliteColor"],
        ["align", "list", "lineHeight"],
        ["outdent", "indent"],

        ["table", "horizontalRule", "link", "image", "video"],
        // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
        // ['imageGallery'], // You must add the "imageGalleryUrl".
        // ["fullScreen", "showBlocks", "codeView"],
        [ "showBlocks", "codeView"],
        ["preview", "print"],
        ["removeFormat"]

        // ['save', 'template'],
        // '/', Line break
      ], // Or Array of button list, eg. [['font', 'align'], ['image']]
    imageRotation: false,
    fontSize: [12, 14, 16, 18, 20],
    colorList: [
      [
        "#828282",
        "#FF5400",
        "#676464",
        "#F1F2F4",
        "#FF9B00",
        "#F00",
        "#fa6e30",
        "#000",
        "rgba(255, 153, 0, 0.1)",
        "#FF6600",
        "#0099FF",
        "#74CC6D",
        "#FF9900",
        "#CCCCCC"
      ]
    ]
  };