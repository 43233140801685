import React, { useState, Fragment } from 'react'
import Layout from '../../components/layout'
import { Link, useLocation } from 'react-router-dom'
import ReactDatatable from "react-data-table-component";
import { CiEdit } from "react-icons/ci";
import styled from '../../assets/css/user.module.css';
import { useNavigate } from 'react-router-dom';
import dummyimg from "../../assets/images/dummypf.png";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { FirstCaps, isEmpty } from '../../lib/common';
import { DepositSupportAction, SupportListAction, UserNameAction, WithDrawSupportAction } from '../../axioscalls/useaxios';
import config from '../../lib/config'

function Namechangerequestdetail() {

    const navigate = useNavigate();
    const location = useLocation()

    // const { detail }=props;
    const [userdata, Setuserdata] = useState(location.state)
    const [detail, Setdetails] = useState(location.state)
    const [Errors, SetErrors] = useState({})
    const [DisableStatus, SetDisableStatus] = useState(false);
    const Wallet_Details = useSelector((state) => state.wallet_detail)
    // const detail = location.state

    const OnChange = (e) => {
        SetErrors({})
        let id = e?.target?.id;
        let value = e?.target?.value;
        Setdetails({
            ...detail,
            ...{ [id]: value },
        })
    }

    const Validate = (type) => {
        let error = {};
        if (type == 'rejected') {
            if (isEmpty(detail?.Message)) {
                error.Message = "Enter Reject Reason to Reject"
            }
        }
        return error;
    }

    const Submit = async (type) => {
        SetDisableStatus(true);
        // let toastid = toast.loading("Validating...");
        let error = Validate(type)
        if (isEmpty(error)) {
            let resp = await UserNameAction({ action: 'update', Id: detail?._id, Status: type, Message: detail?.Message })
            console.log('UserNameAction_resp', resp)
            if (resp?.status) {

                toast.success(resp?.msg, 1000)
                // toast.update(toastid,{render:resp?.msg,autoClose:1000,type:'success',isLoading:false})
                navigate('/namechangerequestlist')
            }
            else {
                // toast.update(toastid,{render:resp?.msg,autoClose:1000,type:'error',isLoading:false})
                toast.error(resp?.msg, 1000)
            }
        }
        else {
            SetErrors(error)
            // toast.update(toastid,{render:"Fix all Validations",autoClose:1000,type:'error',isLoading:false})
            toast.error("Fix all Validations")
        }
        SetDisableStatus(false);
    }

    return (
        <div>
            <Layout>
                <div className={`${styled.innercontents}`}>
                    <div className={`row mx-0`}>
                        <div className={`col-12`}>
                            <div className={`${styled.tablesec}`}>
                                <button type='button' className={`themebtn ${styled.addbtn}`} onClick={() => navigate(-1)}>Back</button>
                                <h5 className={`${styled.cardtitle}`}>User Details</h5>

                                <form>
                                    <div className={`row ${styled.formsec}`}>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>User Name</label>
                                            <input type="text" disabled={true} className={`formcontrol`} placeholder='name' value={detail?.UserId?.UserName} />
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Profile</label>
                                            {/* <img src={dummyimg} className={`${styled.dummyimg} d-block`}/> */}
                                            {isEmpty(detail?.UserId?.Profile) ? <img className={`${styled.dummyimg} d-block`} src={dummyimg} alt="Pnorofile" />
                                                : <img className={`${styled.dummyimg} d-block`} src={`${config.IMG_URL}/profile/${detail?.UserId?._id}/${detail?.UserId?.Profile}`} alt="Profile" />}
                                        </div>
                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>Email</label>
                                            <input type="email" disabled={true} className={`formcontrol`} placeholder='para@gmail.com' value={detail?.UserId?.EmailId} />
                                        </div>

                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <label for="exampleInputEmail1" className={`formlabel`}>New User Name (Requested)</label>
                                            <input type="text" disabled={true} className={`formcontrol`} placeholder='subject' value={detail?.UserName} />
                                        </div>


                                        <div className={`col-12 ${styled.mbspace}`}>
                                            <button className={`themebtn ${styled.addbtn} ${styled.mts}`} type='button' onClick={() => navigate("/kycdetail", { state: { ...detail.UserId, from: 'view' } })} >View KYC</button>
                                            {/* <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`} onClick={() => Submit()} disabled={detail?.ticketStatus == 'closed' || DisableStatus} >{detail?.ticketStatus == 'closed' ? "Replied" : "Reply"}</button> */}
                                        </div>


                                        {(detail?.AdminId?.EmailId && Wallet_Details?.payload?.Role == "superadmin") ?
                                            <div className={`col-12 ${styled.mbspace}`}>
                                                <label for="exampleInputEmail1" className={`formlabel`}>Replied By</label>
                                                <input type="text" className={`formcontrol`} placeholder='' id='ReplyMessage' value={detail?.AdminId?.EmailId} onChange={(e) => OnChange(e)} disabled={true} />

                                            </div>
                                            :
                                            <></>}

                                        {
                                            detail?.Status != 'accepted'
                                                ?

                                                <div className={`col-12 ${styled.mbspace}`}>
                                                    <label for="exampleInputEmail1" className={`formlabel`}>Reject Message:(Reject Message is Required for Rejection)</label>
                                                    <input type="text" className={`formcontrol`} id="Message" placeholder="Enter Reject Reason" value={detail?.Message} onChange={(e) => OnChange(e)} disabled={detail?.Status != 'pending'} />
                                                    {
                                                        Errors?.Message ?
                                                            <span className='error_msg'>{Errors?.Message}</span>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                        }
                                        {
                                            detail?.Status == 'pending' ?
                                                <div className={`col-12 ${styled.mbspace}`}>
                                                    <button className=' mt-2 allbtn' type='button' onClick={() => Submit("accepted")} disabled={detail?.Status != 'pending' || DisableStatus} >Accept</button>

                                                    <br />

                                                    <button className=' mt-2 allbtn' type='button' onClick={() => Submit("rejected")} disabled={detail?.Status != 'pending' || DisableStatus} >Reject</button>
                                                </div>
                                                :
                                                <div className={`col-12 ${styled.mbspace}`}>
                                                    <p>The Request has been {FirstCaps(detail?.Status)}</p>
                                                </div>
                                        }
                                    </div>
                                </form>

                                <button type='button' className={`themebtn ${styled.addbtn} ${styled.mts}`} onClick={() => Submit()} disabled={detail?.ticketStatus == 'closed' || DisableStatus} >{detail?.ticketStatus == 'closed' ? "Replied" : "Reply"}</button>

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

        </div>
    )
}

export default Namechangerequestdetail